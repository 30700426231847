import { SearchboxParams } from "@basset-la/components-combined/dist/models"
import { AccommodationAppliedFilters } from "../../components/accommodations/AccommodationResults/types"
// import { RateTypeEnum } from "../types/accommodations"
import { stringifyDistribution } from "./common"
import { AutocompleteItem } from "@basset-la/components-products/dist/components/ProductAutocomplete"

export const mapFiltersToUrl = (af: AccommodationAppliedFilters) => {
  let query: any = {}

  if (af.filters.stars) {
    query.stars = af.filters.stars.join(",")
  }
  if (af.filters.category) {
    query.category = af.filters.category.join(",")
  }
  if (af.filters.name) {
    query.name = af.filters.name.join(",")
  }
  if (af.filters.amenities) {
    query.amenities = af.filters.amenities.join(",")
  }
  if (af.filters.price) {
    query.price = af.filters.price.join(",")
  }
  if (af.filters.meal_plan) {
    query.meal_plan = af.filters.meal_plan.join(",")
  }
  if (af.filters.regions) {
    query.regions = af.filters.regions.join(",")
  }
  if (af.filters.providers) {
    query.regions = af.filters.providers.join(",")
  }
  query.order_by = af.order_by || "price_asc"

  return query
}

export const mapSearchParamsToAccommodationsQueryString = (region: AutocompleteItem, searchParams: SearchboxParams) => {
  return {
    region_id: region.id,
    region_type: region.type,
    checkin: searchParams.leg.from!.format("YYYY-MM-DD"),
    checkout: searchParams.leg.to!.format("YYYY-MM-DD"),
    distribution: stringifyDistribution(searchParams.rooms),
    // rate_type: RateTypeEnum.PackageOpaque,
  }
}
