import React from "react"
import styles from "./MobileRoomClusters.styles"
import moment from "moment"
import { useTranslation } from "react-i18next"
import RoomDescription from "./RoomDescription"
import Button from "@material-ui/core/Button"
import { I18N_NS } from "../../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import { RoomCluster, RoomClusterRate } from "@basset-la/components-accommodations/dist/model"
import RateDetail from "@basset-la/components-accommodations/dist/components/RateDetail"

interface Props {
  roomClusters: RoomCluster[]
  selectedRate: RoomClusterRate
  totalGuests: number
  onRateSelect: (clusterId: number, rateIndex: number) => void
  onRoomCheckout: (rateId: string) => void
}

const MobileRoomCluster: React.FC<Props> = ({
  roomClusters,
  selectedRate,
  totalGuests,
  onRateSelect,
  onRoomCheckout,
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const checkin = moment(roomClusters[0].rates[0].checkin)
  const checkout = moment(roomClusters[0].rates[0].checkout)
  const nights = checkout.diff(checkin, "days")

  const handleRoomCheckout = (rateId: string) => {
    onRoomCheckout(rateId)
  }

  const handleRateSelect = (clusterIndex: number, rateIndex: number) => {
    onRateSelect(clusterIndex, rateIndex)
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("MobileRoomCluster.rooms")}</p>
      <p className={styles.rateInformation}>
        {t("MobileRoomCluster.price_for_nights_and_guests", {
          nights: t("MobileRoomCluster.count_night", { count: nights }),
          guests: t("MobileRoomCluster.count_guest", { count: totalGuests }),
        })}
      </p>
      <div className={styles.roomClustersContainer}>
        {roomClusters.map((cluster, i) => (
          <div key={i} className={styles.roomCluster}>
            <div className={styles.roomsContainer}>
              {cluster.rooms.map((room, roomIndex) => (
                <RoomDescription
                  amount={cluster.rooms.length}
                  beds={room.bed_configuration}
                  capacity={room.capacity}
                  name={room.name}
                  key={roomIndex}
                />
              ))}
            </div>
            <div className={styles.ratesContainer}>
              {cluster.rates.map((rate, rateIndex) => (
                <div
                  key={rateIndex}
                  className={styles.rate(theme, rate.id === selectedRate.id)}
                  onClick={() => handleRateSelect(i, rateIndex)}
                >
                  <RateDetail rate={rate} />
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.button(theme)}
                      variant="contained"
                      onClick={() => handleRoomCheckout(rate.id)}
                    >
                      {t("MobileRoomCluster.reserve")}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileRoomCluster
