import React from "react"

const NotAvailableIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="97" height="64" viewBox="0 0 97 64">
      <g fill="none" fillRule="nonzero">
        <rect width="40" height="47" x="33.5" y=".5" fill="#D4E3F0" stroke="#6A79A8" rx="3" />
        <circle cx="17.5" cy="39.5" r="1.5" fill="#6A79A8" />
        <circle cx="13" cy="25" r="3" fill="#D4E3F0" stroke="#6A79A8" stroke-width=".8" />
        <path fill="#FFF" d="M28 34.312V8a2 2 0 0 1 2-2h30.24L69 14.693V51a2 2 0 0 1-2 2H36.996L28 38.324v-4.012z" />
        <path
          stroke="#6E7EAF"
          d="M28.5 34.103L47.125 52.5H67a1.5 1.5 0 0 0 1.5-1.5V14.9l-4.232-4.199L60.034 6.5H30A1.5 1.5 0 0 0 28.5 8v26.103z"
        />
        <path stroke="#6A79A8" stroke-linejoin="round" d="M61 7v8h8z" />
        <path
          stroke="#D4E3F0"
          stroke-linecap="round"
          stroke-width="2"
          d="M34.5 15.25h20M34.5 23.403h28M34.5 29.403h28M44 37.2h18.5M49 44.2h14.5"
        />
        <g stroke="#6A79A8" stroke-linecap="round" stroke-width="2">
          <path d="M5 32.363v7.274M1.363 36h7.274" />
        </g>
        <circle cx="90.5" cy="25.5" r="1.5" fill="#6A79A8" />
        <circle cx="83" cy="19" r="2" fill="#D4E3F0" stroke="#6A79A8" stroke-width=".8" />
        <g stroke="#6A79A8" stroke-linecap="round" stroke-width="2">
          <path d="M93 9.576v4.848M90.576 12h4.848" />
        </g>
        <circle cx="33" cy="47" r="11" fill="#FFF" stroke="#6A79A8" />
        <path stroke="#6A79A8" d="M39 54H28.898C27.85 54 27 53.164 27 52.133V40" />
        <path stroke="#D4E3F0" stroke-linecap="round" stroke-width="2" d="M31 42.8h7M31 47.8h9" />
        <path stroke="#6A79A8" stroke-linecap="square" d="M41.5 55.5l2 2" />
        <path
          fill="#D4E3F0"
          stroke="#6E7EAF"
          d="M44.914 56.92a1.427 1.427 0 0 0-1.99-.033 1.343 1.343 0 0 0-.034 1.927l4.196 4.265a1.427 1.427 0 0 0 1.99.034 1.343 1.343 0 0 0 .034-1.927l-4.196-4.265z"
        />
      </g>
    </svg>
  )
}

export default NotAvailableIcon
