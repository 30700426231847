import { BassetProvider } from "@basset-la/themed-components"
import { useEffect } from "react"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import "moment/locale/es"
import { AuthUserProvider, useConfig } from "@basset-la/components-commons"
import { getUserAgencies } from "../api/auth"
import { useAuth0 } from "@auth0/auth0-react"

export interface Props {
  variant: "FLIGHTS" | "ACCOMMODATIONS"
  children?: React.ReactNode
}

const AppTheme: React.FC<Props> = ({ variant, children }) => {
  const auth0 = useAuth0()
  const { config } = useConfig()

  useEffect(() => {
    let head = document.getElementsByTagName("head")[0]
    let linkEl = document.createElement("link")
    linkEl.type = "image/x-icon"
    linkEl.rel = "icon"
    // remove existing favicons
    let links = head.getElementsByTagName("link")
    for (let i = links.length; --i >= 0; ) {
      const rel = links[i].getAttribute("rel")
      if (rel && /\bicon\b/i.test(rel)) {
        head.removeChild(links[i])
      }
    }
    linkEl.href = config.favicon_url

    head.appendChild(linkEl)
  }, [config])

  const theme = {
    colors: {
      brand_darker: config.brand.colors.darker,
      brand_primary: config.brand.colors.primary,
      brand_variant: config.brand.colors.variant,
      text_primary: config.brand.colors.text_primary,
      text_variant: config.brand.colors.text_variant,
      background_primary: config.brand.colors.background_primary,
      background_variant: config.brand.colors.background_variant,
      background_darker: config.brand.colors.background_darker,
      text_white: config.brand.colors.text_white,
      error: config.brand.colors.error,
      info: config.brand.colors.info,
      success: config.brand.colors.success,
      warning: config.brand.colors.warning,
      searchbox: {
        background: config.search_box_colors.background,
        text: config.search_box_colors.text,
      },
    },
    logo: config.brand.logo,
    mobile_logo: config.brand.mobile_logo,
    brand_colors: {
      background_color: "#FFFFFF",
      click_color: config.brand.colors.primary,
      highlight_color: config.search_box_colors.background,
      selection_color: config.brand.colors.variant,
    },
    searchbox_colors: {
      background_color: config.search_box_colors.text,
      click_color: config.brand.colors.primary,
      highlight_color: config.search_box_colors.background,
      selection_color: config.brand.colors.variant,
    },
  }

  return (
    <AuthUserProvider
      auth0={auth0}
      config={config}
      getUserAgencies={getUserAgencies}
      fallback={<AppLoader variant={variant} />}
    >
      <BassetProvider theme={theme}>{children}</BassetProvider>
    </AuthUserProvider>
  )
}

export default AppTheme
