import React from "react"
import { useTranslation } from "react-i18next"
import ErrorMessage from "./ErrorMessage"
import { I18N_NS } from "../../../utils/constants"
import { FetchError } from "@basset-la/components-flights/dist/model/types"

export interface Props {
  error: FetchError | null
}

const ErrorMessageWrapper: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation(I18N_NS)

  if (error && error.statusCode !== 404) {
    const res = error.message.match(/([A-Z_]+)\s{{(.+)}}/)
    const completeMessageText = res
      ? t("FlightsResults.apiErrors.".concat(res[1]), { param: res[2] })
      : t("FlightsResults.messages.no_results")

    return (
      <ErrorMessage
        title={t("FlightsResults.errors.something_went_wrong")}
        message={t("FlightsResults.errors.retry_search", {
          errorMessage: completeMessageText,
        })}
        type="error"
      />
    )
  }

  return (
    <ErrorMessage
      title={t("FlightsResults.messages.no_results")}
      message={t("FlightsResults.messages.no_more_seats_or_flights")}
      type="no-results"
    />
  )
}

export default ErrorMessageWrapper
