import { SearchboxParams } from "@basset-la/components-combined/dist/models"
import { AccommodationAppliedFilters } from "../components/accommodations/AccommodationResults/types"
import { mapFiltersToUrl, mapSearchParamsToAccommodationsQueryString } from "../utils/helpers/accommodations"
import { stringifyDistribution } from "../utils/helpers/common"
import { Pagination } from "../utils/types/accommodations"
import qs from "qs"
import { AutocompleteItem } from "@basset-la/components-products/dist/components/ProductAutocomplete"
import { Cart } from "../utils/types/combined"
import { FetchError } from "@basset-la/components-flights/dist/model"
import { getSessionId } from "../utils/helpers/SessionStorage"
import { Config } from "@basset-la/components-commons"

export const getAccommodationInformation = async (config: Config, id: string) => {
  const url = process.env.REACT_APP_ACCOMMODATIONS_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ACCOMMODATIONS_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const res = await fetch(`${url}/${id}?language=${config.language}&channel=WEB`, {
    headers: {
      "x-client-id": config.agency_id,
      "x-api-key": apiKey,
    },
  })
  if (res.ok) {
    const accommodation = await res.json()
    return accommodation
  }
  throw new Error("Failed to get accommodation")
}

export const getRoomClusters = async (config: Config, cart: Cart, hotelID: string, searchParams: SearchboxParams) => {
  const baseURL = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_COMBINED_PRODUCTS_API_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const checkin = searchParams.leg.from!.format("YYYY-MM-DD")
  const checkout = searchParams.leg.to!.format("YYYY-MM-DD")
  const distribution = stringifyDistribution(searchParams.rooms)

  const uri = `${baseURL}/accommodations/rates?cart_id=${
    cart.id
  }&hotel_id=${hotelID}&checkin=${checkin}&checkout=${checkout}&distribution=${distribution}&site=${
    config.country
  }&source=${config.source ? config.source : ""}&channel=WEB&currency=ARS&language=${config.language}`

  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.agency_id,
    },
  })

  const json = await res.json()
  if (res.ok) {
    return json
  }
  throw new Error(json.message)
}

export const checkRate = async (config: Config, rateID: string) => {
  const baseURL = process.env.REACT_APP_RATES_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_RATES_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const uri = `${baseURL}/${rateID}/price-check?site=${config.country}&channel=WEB&source=${
    config.source ? config.source : ""
  }`
  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.agency_id,
    },
  })
  if (res.ok) {
    return res.json()
  }
  throw new Error("Failed to get rate")
}

export const getRoomClusterById = async (config: Config, cart: Cart, rateID: string) => {
  const baseURL = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_COMBINED_PRODUCTS_API_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const uri = `${baseURL}/accommodations/rates/${rateID}?cart_id=${cart.id}&site=${config.country}&channel=WEB&source=${
    config.source ? config.source : ""
  }`
  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.agency_id,
    },
  })
  if (res.ok) {
    return res.json()
  }
  throw new Error("Failed to get rate")
}

export const searchAccommodations = async (
  config: Config,
  cart: Cart,
  region: AutocompleteItem,
  searchParams: SearchboxParams,
  filters: AccommodationAppliedFilters,
  pagination: Pagination
) => {
  let baseURL = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_COMBINED_PRODUCTS_API_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const query = qs.stringify({
    ...mapSearchParamsToAccommodationsQueryString(region, searchParams),
    ...mapFiltersToUrl(filters),
    ...pagination,
    cart_id: cart.id,
    site: config.country,
    source: config.source || "",
    channel: "WEB",
    language: config.language,
    currency: "ARS",
  })

  let uri = `${baseURL}/accommodations/availabilities?${query}`

  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": config.agency_id,
      "x-session-id": getSessionId() || "",
    },
  })

  const json = await res.json()
  if (res.ok) {
    return json
  }

  if (res.status === 504) {
    throw new Error("Timeout when is getting availability")
  }

  throw new FetchError(res.status, json.message)
}
