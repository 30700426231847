import qs from "qs"
import { FlightResponseModel } from "../../../api/flights"
import {
  Cluster as ClusterModel,
  FetchError,
  FlightResultsModel,
  FlightsClusterAvailableFilters,
  ItineraryRecommendations,
  Price,
  Segment,
  UpSellFare,
} from "@basset-la/components-flights/dist/model"
import { Cart } from "../../../utils/types/combined"
import { fillSelectedBrand } from "@basset-la/components-flights/dist/utils/helpers"

export interface UpdateUpSellFareModel {
  selectedBrand: number
  price: Price
  upsellFares?: UpSellFare[]
  idx: number
}

export interface ReloadClusterModel {
  idx: number
}

export interface FlightResultsReducerAction {
  type:
    | "set"
    | "append"
    | "error"
    | "hide_offer_alert"
    | "hide_selected_flight_alert"
    | "reset_clusters"
    | "reset_all"
    | "update_upsell_fares"
    | "reload_cluster"
  cart?: Cart
  payload?: FlightResponseModel | FetchError | UpdateUpSellFareModel | ReloadClusterModel
}

export interface CombinedFlightResultsModel extends FlightResultsModel {
  selectedBrands: number[]
  showSelectedFlightAlert: boolean
  recommendations: ItineraryRecommendations | null
}

export const flightResultsReducer = (
  results: CombinedFlightResultsModel,
  action: FlightResultsReducerAction
): CombinedFlightResultsModel => {
  const checkAlert = (clusters: ClusterModel[]) => {
    var view = false
    const offerPrice = qs.parse(window.location.search.substr(1)).offer_price || ""
    if (clusters.length > 0 && offerPrice) {
      const price = clusters[0].price.total
      const formattedPrice = price.toFixed(2)
      if (offerPrice !== formattedPrice) {
        view = true
      }
    }
    return view
  }

  const checkSegment = (segment: Segment, id: string) => {
    for (let opt of segment.options) {
      if (opt.id === id) {
        return true
      }
    }
    return false
  }

  const checkSelectedFlightAlert = (clusters: ClusterModel[], cart: Cart) => {
    const parts = cart.flight.flight_itinerary_id.split("_")
    const departureId = parts[0]
    const returnId = parts[1]
    return (
      clusters.length === 0 ||
      !checkSegment(clusters[0].segments[0], departureId) ||
      !checkSegment(clusters[0].segments[1], returnId)
    )
  }

  switch (action.type) {
    case "error":
      return {
        ...results,
        error: action.payload as FetchError,
      }

    case "hide_offer_alert":
      return {
        ...results,
        viewAlert: false,
      }

    case "hide_selected_flight_alert":
      return {
        ...results,
        showSelectedFlightAlert: false,
      }

    case "reset_clusters":
      return {
        ...results,
        clusters: [],
        recommendations: null,
        selectedBrands: [],
        error: null,
      }

    case "reset_all":
      return {
        ...results,
        clusters: [],
        matrix: [],
        recommendations: null,
        selectedBrands: [],
        filters: {} as FlightsClusterAvailableFilters,
        error: null,
        showSelectedFlightAlert: false,
      }

    case "set":
      const res = action.payload as FlightResponseModel

      const clusters = res && res.clusters ? res.clusters : []
      const viewAlert = checkAlert(clusters)
      const showSelectedFlightAlert = checkSelectedFlightAlert(clusters, action.cart!)
      return {
        clusters: clusters,
        selectedBrands: clusters.map((c) => fillSelectedBrand(c)),
        recommendations: res.recommendations,
        matrix: res && res.matrix ? res.matrix : [],
        filters: res && res.filters ? res.filters : ({} as FlightsClusterAvailableFilters),
        total: res && res.total ? res.total : 0,
        viewAlert: viewAlert,
        error: null,
        showSelectedFlightAlert: showSelectedFlightAlert,
      }

    case "append":
      return {
        ...results,
        clusters: [...results.clusters, ...(action.payload as FlightResponseModel).clusters],
        selectedBrands: [
          ...results.selectedBrands,
          ...(action.payload as FlightResponseModel).clusters.map((c) => fillSelectedBrand(c)),
        ],
      }

    case "update_upsell_fares":
      const m = action.payload as UpdateUpSellFareModel

      const newClusters = [...results.clusters]
      const newSelectedBrands = [...results.selectedBrands]

      newClusters[m.idx] = {
        ...newClusters[m.idx],
        price: m.price,
        upsell_fares: m.upsellFares,
      }
      newSelectedBrands[m.idx] = m.selectedBrand

      return {
        ...results,
        clusters: newClusters,
        selectedBrands: newSelectedBrands,
      }

    case "reload_cluster":
      const rcm = action.payload as ReloadClusterModel

      const c = [...results.clusters]
      c[rcm.idx] = { ...results.clusters[rcm.idx] }

      return {
        ...results,
        clusters: c,
      }
  }
}
