import React from "react"
import styles from "./RateTotal.styles"
import { useTranslation } from "react-i18next"
import Sticky from "react-stickynode"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import { RoomClusterRate } from "@basset-la/components-accommodations/dist/model"
import { I18N_NS } from "../../../utils/constants"
import RateTotalWeb from "../RateTotalWeb/RateTotalWeb"

interface Props {
  rate: RoomClusterRate
  bottomBoundary?: string | number
  top?: string | number
  onSelect: () => void
  channel?: string
  taxesDisclaimers: string[]
}

const RateTotal = ({ rate, top, bottomBoundary, onSelect, taxesDisclaimers }: Props) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <Sticky top={top} innerZ={130} bottomBoundary={bottomBoundary}>
      <div className={styles.container}>
        <RateTotalWeb isMobile={false} rate={rate} taxesDisclaimers={taxesDisclaimers} />
        <Button className={styles.button(theme, false)} disabled={false} variant={"contained"} onClick={onSelect}>
          {t("RateTotal.select")}
        </Button>
      </div>
    </Sticky>
  )
}

export default RateTotal
