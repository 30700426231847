import { SearchboxConfig } from "@basset-la/components-accommodations"
import moment from "moment"

export const I18N_NS = "combined-frontend"

export const NO_VALUE = "NO_VALUE"

export const ROUND_TRIP = "ROUND_TRIP"

export const CLUSTERS_PER_PAGE = 20

export const HotelSearchboxInitialConfig: SearchboxConfig = {
  minDate: moment().add(30, "days"),
  maxDate: moment().add(330, "days"),
  maxRooms: 4,
  maxRoomCapacity: 8,
  minimumNights: 0,
  maximumNights: 30,
}
