import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "64px",
    gap: "32px",
    "@media(max-width:1024px)": {
      flexDirection: "column",
      marginTop: "32px",
      gap: "16px",
    },
  }),

  error: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px",
    "@media(max-width:1024px)": {
      alignItems: "center",
    },
  }),

  title: (t: Theme) =>
    css({
      " > span": {
        color: t.colors.brand_darker,
      },
    }),

  text: css({
    textAlign: "center",
  }),

  button: css({
    marginTop: "16px",
    "&.MuiButton-root": {
      textTransform: "unset",
    },
  }),
}

export default styles
