import React from "react"
import styles from "./ListHeader.styles"
import Button from "@basset-la/components-commons/dist/components/Button"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ListIcon from "@material-ui/icons/List"

export interface Props {
  isMobile: boolean
  hotelsFound: number
  sortingComponent: React.ReactNode
  viewMap: boolean
  onViewMap: () => void
}

const ListHeader: React.FC<Props> = ({ isMobile, hotelsFound, sortingComponent, viewMap, onViewMap }) => {
  const { t } = useTranslation(I18N_NS)

  const handleMapButtonClick = () => {
    onViewMap()
  }

  if (isMobile) {
    return <>{sortingComponent}</>
  }

  return (
    <div className={styles.root}>
      <Text className={styles.text} variant="bold" size={24} component="p">
        {t("ListHeader.hotelsFound", { count: hotelsFound })}
      </Text>
      <div className={styles.buttonContainer}>
        {sortingComponent}
        <Button variant="outlined" shape="rounded" onClick={handleMapButtonClick}>
          {viewMap && (
            <>
              <ListIcon className={styles.icon} />
              {t("ListHeader.listView")}
            </>
          )}
          {!viewMap && (
            <>
              <LocationOnIcon className={styles.icon} />
              {t("ListHeader.mapView")}
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

export default ListHeader
