import { createBrowserHistory } from "redux-first-routing"
import { Router, Switch, Route } from "react-router-dom"
import AppConfig from "./AppConfig"
import { default as FlightResults } from "./flights/Results/Results"
import { default as AccommodationResults } from "./accommodations/Results/Results"
import { default as AccommodationDetails } from "./accommodations/Details/Details"
import { Auth0Provider } from "@auth0/auth0-react"

const history = createBrowserHistory()

const AppRoutes: React.FC = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
    >
      <Router history={history}>
        <Switch>
          <Route path="/combined/flights">
            <AppConfig variant="FLIGHTS">
              <FlightResults />
            </AppConfig>
          </Route>

          <Route path="/combined/accommodations/:hotelId/">
            <AppConfig variant="ACCOMMODATIONS">
              <AccommodationDetails />
            </AppConfig>
          </Route>

          <Route path="/combined/accommodations">
            <AppConfig variant="ACCOMMODATIONS">
              <AccommodationResults />
            </AppConfig>
          </Route>
        </Switch>
      </Router>
    </Auth0Provider>
  )
}

export default AppRoutes
