import { v4 as uuidv4 } from "uuid"

const KEY = "x-session-id"

export const getSessionId = () => {
  let sessionId = window.sessionStorage.getItem(KEY)
  if (!sessionId) {
    sessionId = uuidv4() || ""
    window.sessionStorage.setItem(KEY, sessionId || "")
  }

  return sessionId
}
