import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  resultMessage: (theme: Theme, type: "error" | "no-results") =>
    css({
      padding: "24px",
      display: "flex",
      flexFlow: "row nowrap",
      backgroundColor: type === "no-results" ? "rgba(70, 128, 233, 0.12)" : "rgba(208, 2, 27, 0.1)",
      color: theme.colors.text_white,
      borderRadius: "4px",
    }),
  resultMessageIcon: css({
    paddingRight: "16px",
  }),
}

export default styles
