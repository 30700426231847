import React from "react"
import styles from "./ErrorMessage.styles"
import { useTheme } from "@basset-la/themed-components"
import Text from "@basset-la/components-commons/dist/components/Text"
import ErrorIcon from "./img/ErrorIcon"
import NoResultsIcon from "./img/NoResultsIcon"

export interface Props {
  title: string
  message: string
  type: "error" | "no-results"
}

const ErrorMessage: React.FC<Props> = ({ title, message, type }) => {
  const theme = useTheme()

  return (
    <div className={styles.resultMessage(theme, type)}>
      <div className={styles.resultMessageIcon}>
        {type === "error" && <ErrorIcon width={148} height={74} />}
        {type === "no-results" && <NoResultsIcon width={148} height={74} />}
      </div>
      <div>
        <Text size={16} component="p" variant="bold">
          {title}
        </Text>
        <Text size={14} component="p">
          {message}
        </Text>
      </div>
    </div>
  )
}

export default ErrorMessage
