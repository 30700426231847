import React, { useEffect, useReducer, useState } from "react"
import qs from "qs"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga"
import { getAccommodationsAdvertising } from "../../../api/advertising"
import { CLUSTERS_PER_PAGE, I18N_NS } from "../../../utils/constants"
import Searchbox from "@basset-la/components-combined/dist/components/Searchbox"
import SearchboxMobile from "@basset-la/components-combined/dist/components/SearchboxMobile"
import Stepper from "@basset-la/components-combined/dist/components/Stepper"
import { getRegionsForFlights, getRegionsforAccommodations, getEntities } from "../../../api/geo"
import moment from "moment"
import AccommodationResults from "../AccommodationResults/AccommodationResults"
import { AdvertisingInfo } from "@basset-la/components-accommodations/dist/model"
import Wrapper from "../../Wrapper"
import { useHistory, useLocation } from "react-router"
import { searchAccommodations } from "../../../api/accommodations"
import { hotelReducer, HotelResultsInitialValue } from "./reducer"
import {
  addGA,
  getCartId,
  getEnabledStep,
  getGuests,
  parseSearchboxParams,
  removeCartState,
  saveEnabledStep,
  searchParamsToCheckoutPath,
  searchParamsToFlightResultsPath,
  searchParamsToHotelResultsPath,
  searchParamsToNewFlightResultsPath,
} from "../../../utils/helpers/common"
import { SearchboxParams, StepType } from "@basset-la/components-combined/dist/models"
import { searchParamsToHotelDetailsPath } from "../../../utils/helpers/common"
import { Pagination } from "../../../utils/types/accommodations"
import { AccommodationAppliedFilters } from "../AccommodationResults/types"
import { Cart } from "../../../utils/types/combined"
import { getCart } from "../../../api/combined"
import HotelNotAvailableError from "../HotelNotAvailableError/HotelNotAvailableError"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { getAirlineAutocomplete } from "../../../api/autocomplete"
import { FetchError } from "@basset-la/components-flights/dist/model"
import { useConfig } from "@basset-la/components-commons"

const Results: React.FC = () => {
  const { t, i18n } = useTranslation(I18N_NS)
  const { config } = useConfig()
  const { search } = useLocation()
  const history = useHistory()

  const [sp, q] = parseSearchboxParams(search)

  const [pagination, setPagination] = useState<Pagination>({ offset: 0, limit: CLUSTERS_PER_PAGE })
  const [appliedFilters, setAppliedFilters] = useState<AccommodationAppliedFilters>({
    filters: {},
    order_by: "",
  })
  const [queries] = useState(q)
  const [searchParams] = useState(sp)
  const [cart, setCart] = useState<Cart | null>(null)
  const [isFetching, setFetching] = useState(true)
  const [isPaginating, setPaginating] = useState(false)
  const [advertising, setAdvertising] = useState({} as AdvertisingInfo)
  const [results, dispatchResults] = useReducer(hotelReducer, HotelResultsInitialValue)
  const [contentExpired, setContentExpired] = useState(false)
  const isMobile = useMediaQuery("(max-width: 1024px)")

  useEffect(() => {
    document.title = t("accommodations.Results.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
    saveEnabledStep("hotel")
  }, [t])

  useEffect(() => {
    setFetching(true)
    const loadCart = async () => {
      const id = getCartId()
      try {
        const result = await getCart(config, id, searchParams)
        setCart(result)
      } catch (e) {
        console.log(e)
        setFetching(false)
      }
    }

    if (!cart) {
      loadCart()
    }
  }, [config, searchParams, cart])

  useEffect(() => {
    const getAdvertisings = async (): Promise<any> => {
      try {
        const adv = await getAccommodationsAdvertising(config)
        setAdvertising({ ...adv, financials: [] })
      } catch (err) {
        console.error(err)
      }
      return null
    }

    getAdvertisings()
  }, [config])

  useEffect(() => {
    const getAccommodations = async () => {
      if (pagination.offset === 0) {
        setFetching(true)
      } else {
        setPaginating(true)
      }

      try {
        const entities = await getEntities(searchParams.leg.destination!.iata_code!, i18n.language, config.agency_id)
        const name = entities.length > 0 ? entities[0].name.split(",")[0] : ""
        const regions = await getRegionsforAccommodations(i18n.language, config.agency_id, config.country)(name)
        const region = regions.length > 0 ? regions[0] : { id: "", name: "", type: "CITY" }

        let attempts = 0
        let error: any
        while (attempts < 3) {
          try {
            const results = await searchAccommodations(config, cart!, region, searchParams, appliedFilters, pagination)
            dispatchResults({
              type: pagination.offset > 0 ? "append" : "set",
              cart: cart!,
              payload: {
                results: results,
                queries: queries,
              },
            })
            error = null
            break
          } catch (e: any) {
            error = e
            if (e instanceof FetchError) {
              // Check message: {"code":404,"message":"itineraries not found"} => content expired
              const errMsg = JSON.parse(e.message)
              if (errMsg && errMsg.message && errMsg.message === "itineraries not found") {
                setContentExpired(true)
              }
              break
            }
            attempts++
          }
        }
        if (error) {
          handleGetAccommodationsError(error)
        }
      } catch (e: any) {
        handleGetAccommodationsError(e)
      } finally {
        setFetching(false)
        setPaginating(false)
      }
    }

    if (searchParams && cart) {
      getAccommodations()
    }
  }, [config, cart, queries, i18n.language, searchParams, pagination, appliedFilters])

  const handleGetAccommodationsError = (error: any) => {
    console.log(error)
    dispatchResults({ type: "clear" })
  }

  const handleGoToDetail = async (hotelId: string) => {
    let path = searchParamsToHotelDetailsPath(cart!.id, hotelId, searchParams)
    path = addGA(search, path)
    history.push(path)
  }

  const handleFilterChange = (f: AccommodationAppliedFilters) => {
    setAppliedFilters(f)
  }

  const handlePagination = () => {
    const offset = pagination.offset + CLUSTERS_PER_PAGE
    const limit = pagination.limit + CLUSTERS_PER_PAGE
    setPagination({
      limit,
      offset,
    })
  }

  const handleSearch = (params: SearchboxParams) => {
    let path = searchParamsToNewFlightResultsPath(params)
    const queryParams = qs.parse(window.location.search.substr(1))
    if (queryParams._ga) {
      path = `${path}&_ga=${queryParams._ga}`
    }
    setCart(null)
    removeCartState()
    history.push(path)
  }

  const handleCloseOfferAlert = () => {
    dispatchResults({ type: "hide_offer_alert" })
  }

  const handleCloseSelectedHotelAlert = () => {
    dispatchResults({ type: "hide_selected_hotel_alert" })
  }

  const handleBannerClick = (url: string) => {
    window.open(url, "_blank")?.focus()
  }

  const handleStepClick = (type: StepType) => {
    let path = ""
    switch (type) {
      case "flight":
        path = searchParamsToFlightResultsPath(cart!.id, searchParams)
        break
      case "hotel":
        path = searchParamsToHotelResultsPath(cart!.id, searchParams)
        break
      case "checkout":
        path = searchParamsToCheckoutPath(cart!.id, searchParams)
        break
    }
    path = addGA(search, path)
    window.location.href = path
  }

  const handleCartItemsExpired = () => {
    removeCartState()
    setContentExpired(false)
    const url = `/combined/flights${search}`
    history.push(url)
  }

  const getStepperComponent = () => {
    if (!cart) return <></>

    return (
      <Stepper
        accommodation={{
          hotelName: cart.accommodation.name,
          totalRooms: cart.accommodation.total_rooms,
        }}
        flight={{
          origin: cart.flight.origin,
          destination: cart.flight.destination,
          from: moment(cart.flight.from),
          to: moment(cart.flight.to),
          totalPassengers: cart.flight.total_passengers,
        }}
        stickyBoundary={{
          top: 0,
          bottom: "#clusterListContainer",
        }}
        currentStep="hotel"
        enabledStep={getEnabledStep()}
        onStepClick={handleStepClick}
      />
    )
  }

  const searchboxProps = {
    getRegions: getRegionsForFlights(i18n.language, config.agency_id, config.country),
    getAirlines: getAirlineAutocomplete(i18n.language, config.agency_id, config.country),
    config: {
      minDate: moment().add(config.search_configuration.min_days, "days"),
      maxDate: moment().add(config.search_configuration.max_days, "days"),
      minNights: 1,
      maxNights: config.search_configuration.range_days,
      maxRooms: 4,
      maxRoomCapacity: 8,
    },
    params: searchParams,
    onSearch: handleSearch,
  }

  const nights =
    searchParams.leg.from && searchParams.leg.to ? searchParams.leg.to.diff(searchParams.leg.from, "days") : 0

  return (
    <Wrapper>
      {contentExpired && !isFetching && <HotelNotAvailableError onGoBack={handleCartItemsExpired} />}

      {isFetching && <ProductLoader is_mobile={isMobile} product_variant="ACCOMMODATIONS_RESULT" variant="WEB" />}

      {!contentExpired && !isFetching && (
        <AccommodationResults
          cart={cart}
          isLoading={isFetching}
          isPaginating={isPaginating}
          showAlert={results.viewAlert}
          results={{
            accommodations: results.accommodations,
            availableFilters: results.availableFilters,
            availableSortingOptions: results.availableSortingOptions,
            totalGuests: getGuests(searchParams.rooms),
            pageSize: CLUSTERS_PER_PAGE,
            nights: nights,
            totalElements: results.totalAccommodations,
            currencyCode: results.currencyCode,
            showSelectedOfferAlert: results.showSelectedHotelAlert,
          }}
          appliedFilters={appliedFilters}
          advertising={advertising}
          stepperComponent={getStepperComponent()}
          searchboxComponent={<Searchbox {...searchboxProps} />}
          searchboxMobileComponent={<SearchboxMobile {...searchboxProps} />}
          onBannerClick={handleBannerClick}
          onCloseOfferAlert={handleCloseOfferAlert}
          onCloseSelectedHotelAlert={handleCloseSelectedHotelAlert}
          onFilterChange={handleFilterChange}
          onPagination={handlePagination}
          onGoToDetail={handleGoToDetail}
        />
      )}
    </Wrapper>
  )
}

export default Results
