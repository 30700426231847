import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  discount: css({
    alignSelf: "flex-end",
  }),

  priceDetail: css({
    display: "flex",
    flexDirection: "column",
  }),

  discountLabel: css({
    marginBottom: "16px",
  }),

  packageRate: (theme: Theme) =>
    css({
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "1.33",
      letterSpacing: "0.1px",
      textAlign: "center",
      color: theme.colors.brand_primary,
    }),

  price: css({
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "1.33",
    letterSpacing: "1.5px",
    textAlign: "center",
    color: "#000",
    marginBottom: "16px",
  }),

  nightsText: css({
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1.71",
    letterSpacing: "0.5px",
    textAlign: "center",
    color: "#000",
    marginBottom: "24px",
  }),

  container: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0px 0px 16px 0px",
    " > div": {
      backgroundColor: "inherit !important",
    },
  }),

  disclaimerContainer: css({
    marginTop: "16px",
    fontSize: "12px",
    color: "#9b9b9b",
    textAlign: "left",
  }),

  button: (theme: Theme, disabled?: boolean) =>
    css({
      color: !disabled ? "#fff !important" : undefined,
      fontSize: "16px !important",
      fontWeight: 300,
      backgroundColor: !disabled ? `${theme.brand_colors.click_color} !important` : undefined,
      width: "100%",
      padding: "8px",
      marginTop: "16px !important",
    }),
}

export default styles
