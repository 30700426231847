import * as React from "react"
import styles from "./RoomClusters.styles"
import RoomsHeader from "./RoomsHeader"
import RoomCluster from "./RoomCluster"
import RateTotal from "./RateTotal"
import MobileRoomClusters from "./MobileRoomClusters"
import CircularProgress from "@material-ui/core/CircularProgress"
import { RoomCluster as RoomClusterModel, RoomClusterRate } from "@basset-la/components-accommodations/dist/model"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  isLoading: boolean
  roomClusters: RoomClusterModel[]
  id?: string
  totalGuests: number
  selectedRate: RoomClusterRate
  onRoomCheckout: (rateId: string) => void
  onAdjustRate?: (v: number) => void
  onRateSelect: (clusterIndex: number, rateIndex: number) => void
  taxesDisclaimers: string[]
}

const RoomClusters: React.FC<Props> = ({
  id,
  isLoading,
  totalGuests,
  onRoomCheckout,
  onRateSelect,
  selectedRate,
  roomClusters,
  taxesDisclaimers,
}) => {
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const handleRoomCheckout = () => {
    if (onRoomCheckout) {
      onRoomCheckout(selectedRate.id)
    }
  }

  if (isMobile) {
    return (
      <div id={id}>
        <MobileRoomClusters
          totalGuests={totalGuests}
          selectedRate={selectedRate}
          roomClusters={roomClusters}
          onRateSelect={onRateSelect}
          onRoomCheckout={handleRoomCheckout}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <RoomsHeader top="#stepperContainer" bottomBoundary="#roomClustersMainContainer" />
      <div className={styles.mainContainer} id={"roomClustersMainContainer"}>
        <div className={styles.roomClustersContainer}>
          {roomClusters.map((cluster, i) => (
            <RoomCluster
              onRateSelect={onRateSelect}
              clusterIndex={i}
              key={i}
              rates={cluster.rates}
              rooms={cluster.rooms}
            />
          ))}
        </div>
        {!isLoading && (
          <div className={styles.totalContainer}>
            <RateTotal
              rate={selectedRate}
              top={216}
              bottomBoundary="#roomClustersMainContainer"
              taxesDisclaimers={taxesDisclaimers}
              onSelect={handleRoomCheckout}
            />
          </div>
        )}
        {isLoading && (
          <div className={styles.loaderContainer}>
            <CircularProgress size={40} />
          </div>
        )}
      </div>
    </div>
  )
}

export default RoomClusters
