import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  buttonContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "1px solid #eaeaea",
  }),

  icon: (color: string) =>
    css({
      color: color,
    }),

  ratesContainer: css({
    borderLeft: "1px solid #eaeaea",
    display: "flex",
    flexDirection: "column",
    flex: "2 2",
  }),

  rateContainer: (t: Theme, selected: boolean) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      cursor: "pointer",
      borderBottom: "1px dashed #eaeaea",
      backgroundColor: selected ? `${t.colors.brand_primary}10` : "",
      "&:hover": {
        backgroundColor: `${t.colors.brand_primary}10`,
      },
    }),

  rateDetailContainer: css({
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    justifyContent: "center",
  }),

  container: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "3px",
    backgroundColor: "#fff",
  }),

  roomsDescriptionContainer: css({
    display: "flex",
    flexDirection: "column",
    flex: "1 1",
    padding: "32px 16px",
    maxWidth: "280px",
  }),
}

export default styles
