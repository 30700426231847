import React, { useState, useEffect, useRef } from "react"
import styles from "./FlightsResults.styles"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { I18N_NS } from "../../../utils/constants"
import LinearProgress from "@material-ui/core/LinearProgress"
import ErrorMessageWrapper from "./ErrorMessageWrapper"
import Advertising from "@basset-la/components-commons/dist/components/Advertising"
import Alert from "@basset-la/components-commons/dist/components/Alert"
import Text from "@basset-la/components-commons/dist/components/Text"
import {
  AdvertisingInfo,
  FlightType,
  FlightClusterFilters,
  OrderByOptions,
} from "@basset-la/components-flights/dist/model/types"
import PricesMatrix, { CellInfo } from "@basset-la/components-flights/dist/components/PricesMatrix"
import FlightsFilters from "@basset-la/components-flights/dist/components/FlightsFilters"
import PricesMatrixPlaceholder from "@basset-la/components-flights/dist/components/PricesMatrixPlaceholder"
import FlightsResultsPlaceholder from "@basset-la/components-flights/dist/components/FlightsResultsPlaceholder"
import ItineraryRecommendations from "@basset-la/components-flights/dist/components/ItineraryRecommendations"
import ClusterList from "./ClusterList"
import { Cart } from "../../../utils/types/combined"
import { CombinedFlightResultsModel } from "../Results/reducer"
import { Cluster as ClusterModel, RecommendedItinerary } from "@basset-la/components-flights/dist/model"

export interface Props {
  isLoading: boolean
  flightType: FlightType
  advertising?: AdvertisingInfo
  cart: Cart | null
  appliedFilters: FlightClusterFilters
  orderBy: OrderByOptions
  flightResults: CombinedFlightResultsModel
  searchboxComponent: React.ReactNode
  searchboxMobileComponent: React.ReactNode
  stepperComponent: React.ReactNode
  onClickMatrix: (item: CellInfo) => void
  onFilter: (f: FlightClusterFilters) => void
  onBannerClick?: (url: string) => void
  onCloseOfferAlert: () => void
  onCloseSelectedFlightAlert: () => void
  onPaginatedSearch: () => void
  onBuy: (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => void
  onBuyItinerary: (itinerary: RecommendedItinerary) => void
  onOpenBrandSelectionDialog: (c: ClusterModel, idx: number, selectedOptions: number[], selectedBrand: number) => void
  onChangeOrder: (order: OrderByOptions) => void
}

const FlightsResults: React.FC<Props> = ({
  isLoading,
  flightType,
  advertising,
  cart,
  flightResults,
  appliedFilters,
  orderBy,
  searchboxComponent,
  searchboxMobileComponent,
  stepperComponent,
  onClickMatrix,
  onFilter,
  onBannerClick,
  onCloseOfferAlert,
  onCloseSelectedFlightAlert,
  onBuy,
  onBuyItinerary,
  onPaginatedSearch,
  onOpenBrandSelectionDialog,
  onChangeOrder,
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const containerRef = useRef(null)

  const [progress, setProgress] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    if (containerRef) {
      setContainerWidth(containerRef.current!["clientWidth"] - 32)
    }
  }, [containerRef])

  useEffect(() => {
    const startTimer = () => {
      setProgress(0)
      const t = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 95)
        })
      }, 300)
      return t
    }

    const stopTimer = () => {
      setProgress(100)
      clearInterval()
    }

    if (isLoading) startTimer()
    else stopTimer()
  }, [isLoading])

  const handleClickMatrix = (item: CellInfo) => {
    onClickMatrix(item)
  }

  const handleSubmitFilters = (f: FlightClusterFilters) => {
    onFilter(f)
  }

  const handleBannerClick = (url: string) => {
    if (onBannerClick) {
      onBannerClick(url)
    }
  }

  const handleCloseOfferAlert = () => {
    onCloseOfferAlert()
  }

  const handleCloseSelectedFlightAlert = () => {
    onCloseSelectedFlightAlert()
  }

  const handlePaginatedSearch = () => {
    onPaginatedSearch()
  }

  const handleBuy = (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => {
    onBuy(clusterIndex, selectedOptions, selectedBrand)
  }

  return (
    <div className={styles.root}>
      <div className={styles.container(theme)}>
        <div className={styles.leftContainer}>
          <div className={styles.searchbox}>{searchboxComponent}</div>
          {flightResults.filters && appliedFilters && (
            <div className={styles.filters}>
              <FlightsFilters
                availableFilters={flightResults.filters}
                appliedFilters={appliedFilters}
                onSubmitFilters={handleSubmitFilters}
                flightType={flightType}
              />
            </div>
          )}
        </div>
        <div ref={containerRef} className={styles.rightContainer}>
          {isLoading && (
            <LinearProgress className={styles.progressStyle(theme)} variant="determinate" value={progress} />
          )}
          {flightResults.error || ((flightResults.clusters.length === 0 || !cart) && !isLoading) ? (
            <ErrorMessageWrapper error={flightResults.error} />
          ) : (
            <>
              {advertising && advertising.top && advertising.top.banners.length > 0 && containerWidth && (
                <div className={styles.advertising}>
                  <Advertising width={`${containerWidth}px`} banner={advertising.top} onClick={handleBannerClick} />
                </div>
              )}
              <div className={styles.stepper}>{stepperComponent}</div>
              <div className={styles.mobSearchbox}>{searchboxMobileComponent}</div>
              {!isLoading && flightResults.viewAlert && (
                <div className={styles.alertContainerStyle}>
                  <Alert onClose={handleCloseOfferAlert}>
                    <Text size={14} variant="regular">
                      {t("FlightsResults.notAvailableOfferAlert")}
                    </Text>
                  </Alert>
                </div>
              )}
              {!isLoading && flightResults.showSelectedFlightAlert && (
                <div className={styles.alertContainerStyle}>
                  <Alert onClose={handleCloseSelectedFlightAlert}>
                    <Text size={14} variant="regular">
                      {t("FlightsResults.notAvailableCart")}
                    </Text>
                  </Alert>
                </div>
              )}
              <div className={styles.matrix(theme)}>
                {flightResults.matrix.length === 0 && <PricesMatrixPlaceholder />}
                {flightResults.matrix.length > 0 && (
                  <PricesMatrix dataSource={flightResults.matrix} onClick={handleClickMatrix} />
                )}
              </div>
              <div id="clusterListContainer" className={styles.clusterListContainer}>
                {flightResults.clusters.length > 0 && (
                  <>
                    <ItineraryRecommendations
                      recommendations={flightResults.recommendations}
                      orderBy={orderBy}
                      onSelectItinerary={onBuyItinerary}
                      onChangeOrder={onChangeOrder}
                    />
                    <ClusterList
                      isLoading={isLoading}
                      cart={cart!}
                      dataSource={flightResults.clusters}
                      selectedBrands={flightResults.selectedBrands}
                      onPaginatedSearch={handlePaginatedSearch}
                      onSelectItinerary={handleBuy}
                      onOpenBrandSelectionDialog={onOpenBrandSelectionDialog}
                    />
                  </>
                )}
                {isLoading && <FlightsResultsPlaceholder />}
              </div>
              {flightResults.clusters.length > 0 &&
                advertising &&
                advertising.bottom &&
                advertising.bottom.banners.length > 0 &&
                containerWidth && (
                  <div className={styles.advertising}>
                    <Advertising
                      width={`${containerWidth}px`}
                      banner={advertising.bottom}
                      onClick={handleBannerClick}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FlightsResults
