import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
    backgroundColor: "#ffffff",
    padding: "8px 32px",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1.71",
  }),

  container: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "3 3",
  }),

  rooms: css({
    flex: "1 1",
    maxWidth: "280px",
  }),

  rates: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "2 2",
  }),

  price: css({
    display: "flex",
    justifyContent: "center",
    flex: "1 1",
  }),
}

export default styles
