const RoomNotAvailableIcon = () => {
  return (
    <svg width="102" height="79" viewBox="0 0 102 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 21">
        <g id="Group 72">
          <mask
            id="mask0_507_3449"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="10"
            y="0"
            width="81"
            height="78"
          >
            <rect id="Rectangle 27" x="10.3134" y="0.241455" width="80.6186" height="77.4447" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_507_3449)">
            <path
              id="Fill 3971"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.2667 39.3336C40.7671 39.3336 44.4 35.7007 44.4 31.2002C44.4 26.6998 40.7671 23.0669 36.2667 23.0669C31.7662 23.0669 28.1333 26.6998 28.1333 31.2002C28.1333 35.7007 31.7662 39.3336 36.2667 39.3336ZM69.9091 24.4222H47.7273V43.4H25.5455V19H20V59.6667H25.5455V51.5333H75.4545V59.6667H81V35.2667C81 29.2751 76.0368 24.4222 69.9091 24.4222Z"
              fill="#1134D7"
            />
          </g>
        </g>
        <circle id="Oval 11" opacity="0.317922" cx="2.53917" cy="37.807" r="2.53917" fill="#0D207B" />
        <circle
          id="Oval 11 Copy"
          opacity="0.317922"
          cx="78.3928"
          cy="8.49376"
          r="2.53917"
          stroke="#0D207B"
          stroke-width="2"
        />
        <circle
          id="Oval 11 Copy 2"
          opacity="0.317922"
          cx="16.6613"
          cy="70.7035"
          r="2.53917"
          stroke="#0D207B"
          stroke-width="2"
        />
        <g id="Group 23" opacity="0.38">
          <path
            id="Line"
            d="M35.1098 12.2989L30.8515 10.1292"
            stroke="#0D207B"
            stroke-width="2"
            stroke-linecap="round"
          />
          <g id="Group 22">
            <path
              id="Line_2"
              d="M31.8959 13.3432L34.0656 9.0849"
              stroke="#0D207B"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
        </g>
        <g id="Group 23_2" opacity="0.35">
          <path
            id="Line_3"
            d="M96.78 63.9899L100.049 61.2471"
            stroke="#0D207B"
            stroke-width="2"
            stroke-linecap="round"
          />
          <g id="Group 22_2">
            <path
              id="Line_4"
              d="M97.0406 60.9817L99.7834 64.2503"
              stroke="#0D207B"
              stroke-width="2"
              stroke-linecap="round"
            />
          </g>
        </g>
        <circle id="Oval 11_2" opacity="0.149778" cx="64.5881" cy="67.8468" r="2.22177" fill="#0D207B" />
        <circle id="Oval 11_3" opacity="0.26" cx="74.4274" cy="77.6861" r="1.26958" fill="#0D207B" />
        <circle id="Oval 11 Copy 3" opacity="0.26" cx="6.34792" cy="24.4764" r="1.26958" fill="#0D207B" />
      </g>
    </svg>
  )
}

export default RoomNotAvailableIcon
