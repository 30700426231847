import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  filterContainerStyle: css({
    "@media(min-width:1024px),": {
      maxWidth: "320px",
    },
  }),

  hotelListStyle: css({
    paddingBottom: "56px",
    flex: "1 0 0%",
    padding: "0",
    "@media(max-width:600px),": {
      width: "100%",
    },
  }),

  containerStyle: css({
    display: "flex",
    padding: "0px",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "space-between",
    "@media(min-width:1024px),": {
      padding: "8px",
      flexDirection: "row",
    },
  }),

  backgroundStyle: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_variant,
      minHeight: "100vh",
    }),

  closeFiltersStyle: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      " > button": {
        color: `${t.colors.brand_primary} !important`,
        "&:focus": {
          outline: 0,
        },
      },
    }),

  progressStyle: (t: Theme) =>
    css({
      " > div": {
        backgroundColor: `${t.colors.brand_darker} !important`,
      },
    }),

  alertContainer: css({
    "@media(min-width:1024px),": {
      marginBottom: "16px",
    },
  }),

  advertising: css({
    "@media(min-width:1024px),": {
      marginBottom: "16px",
    },
  }),

  stepper: css({
    margin: "0px 8px 8px 8px",
    "@media(min-width: 1024px)": {
      margin: "0px 0px 16px 0px",
    },
  }),

  searchbox: css({
    "@media(max-width:1024px)": {
      display: "none",
    },
  }),

  mobSearchbox: css({
    margin: "0px 8px 8px 8px",
    "@media(min-width:1024px)": {
      display: "none",
    },
  }),
}

export default styles
