import qs from "qs"
import ReactGA from "react-ga"
import moment from "moment"
import { SearchboxParams } from "@basset-la/components-combined/dist/models/searchbox"
import { FlightClass, Stops, Leg } from "@basset-la/components-flights/dist/components/Searchbox/types"
import { NO_VALUE } from "../constants"
import { SearchboxRoom } from "@basset-la/components-accommodations"
import { StepType } from "@basset-la/components-combined/dist/models"
import { CartState } from "../types/combined"

export const parseDistribution = (distribution?: string): SearchboxRoom[] => {
  if (!distribution) return []
  return distribution.split(",").map((r) => {
    const room = r.split("-")
    return {
      adults: parseInt(room[0], 10),
      children: room.slice(1, room.length).map((c) => parseInt(c, 10)),
    }
  })
}

export const stringifyDistribution = (rooms: SearchboxRoom[]): string => {
  let roomsDistribution: string[] = []
  for (let room of rooms) {
    let roomDist = `${room.adults}`
    if (room.children.length) {
      roomDist += `-${room.children.join("-")}`
    }
    roomsDistribution.push(roomDist)
  }
  return roomsDistribution.join(",")
}

export const getAdults = (rooms: SearchboxRoom[]): number => {
  return rooms.map((r) => r.adults).reduce((v, a) => v + a)
}

export const getChildren = (rooms: SearchboxRoom[]): number => {
  return rooms.flatMap((r) => r.children).filter((c) => c !== "NO_VALUE").length
}

export const getGuests = (rooms: SearchboxRoom[]): number => {
  return getAdults(rooms) + getChildren(rooms)
}

export const parseSearchboxParams = (params: string): [SearchboxParams, Record<string, string>] => {
  const queryParams = qs.parse(params, { ignoreQueryPrefix: true }) as Record<string, string>

  const origin = queryParams["from"] || ""
  const destination = queryParams["to"] || ""
  const dates = queryParams["dates"] ? queryParams["dates"].split(",") : []
  const distribution = queryParams["distribution"] || "1"
  const airlines = queryParams["airlines"]?.split(",")

  let legs: Leg = {
    origin: { id: "", iata_code: origin, type: "AIRPORT", name: origin },
    destination: { id: "", iata_code: destination, type: "AIRPORT", name: destination },
    from: moment(dates[0]) || moment(),
    to: moment(dates[1]) || moment(),
  }

  return [
    {
      leg: legs,
      rooms: parseDistribution(distribution),
      flightClass: (queryParams["cabin"] || "NO_VALUE") as FlightClass,
      includeBaggage: (queryParams["checked_baggage"] && queryParams["checked_baggage"] === "true") || false,
      stops: (queryParams["stops"] || "NO_VALUE") as Stops,
      airlines: airlines,
    },
    queryParams,
  ]
}

export const mapSearchboxParamsToUrl = (searchParams: SearchboxParams) => {
  const urlParams = {
    dates: searchParams.leg.to
      ? searchParams.leg.from
        ? `${searchParams.leg.from.format("YYYY-MM-DD")},${searchParams.leg.to.format("YYYY-MM-DD")}`
        : searchParams.leg.from
      : searchParams.leg.from
      ? searchParams.leg.from.format("YYYY-MM-DD")
      : searchParams.leg.from,
    from: searchParams.leg.origin!.iata_code,
    to: searchParams.leg.destination!.iata_code,
    stops: searchParams.stops || NO_VALUE,
    cabin: searchParams.flightClass || NO_VALUE,
    checked_baggage: searchParams.includeBaggage || "false",
    distribution: stringifyDistribution(searchParams.rooms),
    //airlines: searchParams.airlines?.length ? searchParams.airlines.join(",") : "",
  }
  if (searchParams.airlines && searchParams.airlines.length > 0) {
    urlParams["airlines"] = searchParams.airlines.join(",")
  }
  return urlParams
}

const getCartState = (): CartState => {
  const str = sessionStorage.getItem("_cartState") || "null"
  return (JSON.parse(str) as CartState) || { cartId: "", enabledStep: "flight" }
}

const saveCartState = (state: CartState) => {
  sessionStorage.setItem("_cartState", JSON.stringify(state))
}

export const removeCartState = () => {
  sessionStorage.removeItem("_cartState")
}

export const saveCartId = (id: string) => {
  saveCartState({
    ...getCartState(),
    cartId: id,
  })
}

export const getCartId = (): string => {
  return getCartState().cartId
}

export const saveEnabledStep = (step: StepType) => {
  const maxStep = (s1: StepType, s2: StepType): StepType => {
    if (s1 === s2) return s1
    if (s1 === "checkout" || s2 === "checkout") return "checkout"
    if (s1 === "flight") return s2
    if (s2 === "flight") return s1
    return "hotel"
  }
  const state = getCartState()
  const max = maxStep(step, state.enabledStep)
  saveCartState({
    ...state,
    enabledStep: max,
  })
}

export const getEnabledStep = (): StepType => {
  return getCartState().enabledStep
}

export const addGA = (searchStr: string, path: string): string => {
  const sep = path.indexOf("?") >= 0 ? "&" : "?"
  const { _ga } = qs.parse(searchStr.substr(1))
  if (_ga) {
    path = `${path}${sep}_ga=${_ga}`
  } else {
    const ga = ReactGA.ga()
    ga(function (tracker) {
      let clientId = tracker.get("clientId")
      path = `${path}${sep}_ga=${clientId}`
    })
  }
  return path
}

export const searchParamsToNewFlightResultsPath = (params: SearchboxParams): string => {
  removeCartState()
  const urlbase = "/combined/flights"
  const p = qs.stringify(mapSearchboxParamsToUrl(params))
  return `${urlbase}?${p}`
}

export const searchParamsToFlightResultsPath = (id: string, params: SearchboxParams): string => {
  saveCartId(id)
  const urlbase = "/combined/flights"
  const p = qs.stringify(mapSearchboxParamsToUrl(params))
  return `${urlbase}?${p}`
}

export const searchParamsToHotelResultsPath = (id: string, params: SearchboxParams): string => {
  saveCartId(id)
  const urlbase = `/combined/accommodations`
  const p = qs.stringify(mapSearchboxParamsToUrl(params))
  return `${urlbase}?${p}`
}

export const searchParamsToHotelDetailsPath = (id: string, hotelId: string, params: SearchboxParams): string => {
  saveCartId(id)
  const urlbase = `/combined/accommodations/${hotelId}`
  const p = qs.stringify(mapSearchboxParamsToUrl(params))
  return `${urlbase}?${p}`
}

export const searchParamsToCheckoutPath = (id: string, params: SearchboxParams): string => {
  saveCartId(id)
  const urlbase = `/checkout/combined/${id}`
  const p = qs.stringify(mapSearchboxParamsToUrl(params))
  return `${urlbase}?${p}`
}
