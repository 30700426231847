import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { ConfigProvider } from "@basset-la/components-commons"
import { Suspense } from "react"
import AppTheme from "./AppTheme"
import { getAvailableProducts, getClientConfig } from "../api/agencies"

export interface Props {
  variant: "FLIGHTS" | "ACCOMMODATIONS"
  children?: React.ReactNode
}

const AppConfig: React.FC<Props> = ({ variant, children }) => {
  return (
    <Suspense fallback={<AppLoader variant={variant} />}>
      <ConfigProvider
        getClientConfig={getClientConfig}
        getAvailableProducts={getAvailableProducts}
        fallback={<AppLoader variant={variant} />}
      >
        <AppTheme variant={variant}>{children}</AppTheme>
      </ConfigProvider>
    </Suspense>
  )
}

export default AppConfig
