import { FetchError } from "@basset-la/components-flights/dist/model"

export const getAirlineAutocomplete = (language: string, clientId: string, site: string) => async (query: string) => {
  try {
    const url =
      process.env.REACT_APP_AUTOCOMPLETE_API_URL +
      "?entities=AIRLINE&q=" +
      encodeURIComponent(query) +
      "&language=" +
      language.split("-")[0] +
      "&site=" +
      site +
      "&channel=WEB"

    const results = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
        "x-client-id": clientId,
      },
    })
    const json = await results.json()

    if (results.ok) {
      return json
    }

    throw new FetchError(results.status, json.error)
  } catch (error) {}
  return []
}
