import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import XHR from "i18next-xhr-backend"
import { I18N_NS as COMMONS_NS } from "@basset-la/components-commons/dist/utils/constants"
import { I18N_NS as PRODUCTS_NS } from "@basset-la/components-products/dist/utils/constants"
import { I18N_NS as FLIGHTS_NS } from "@basset-la/components-flights/dist/utils/constants"
import { I18N_NS as ACCOMMODATIONS_NS } from "@basset-la/components-accommodations/dist/utils/constants"
import { I18N_NS as COMBINED_NS } from "@basset-la/components-combined/dist/utils/constants"
import { I18N_NS } from "../utils/constants"
import LanguageDetector from "i18next-browser-languagedetector"

const languageNsMap: Record<string, string[]> = {
  "es-AR": [COMMONS_NS, FLIGHTS_NS],
  "es-PE": [ACCOMMODATIONS_NS, PRODUCTS_NS],
  "es-UY": [ACCOMMODATIONS_NS, PRODUCTS_NS],
}

const missingKeyHandler = (lngs: string[], ns: string, key: string) => {
  throw new Error(`missing key '${key}' on namespace '${ns}' for languages '${lngs}'`)
}

const getPath = (lng: string, ns: string) => {
  return `${process.env.REACT_APP_I18N_PATH!}${process.env.REACT_APP_I18N_VERSION}/${lng}/${ns}.json`
}

const loadPath = (lngs: string[], namespaces: string[]) => {
  let lng = lngs[0]
  const ns = namespaces[0]

  if (lng === "es" || lng === "en") {
    return getPath(lng, ns)
  }

  const nsList = languageNsMap[lng] ?? []

  if (nsList.includes(ns)) {
    return getPath(lng, ns)
  }

  return ""
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(XHR)
  .init({
    detection: {
      // order and from where user language should be detected
      order: ["querystring", "cookie", "localStorage", "navigator"],

      // keys or params to lookup language from
      lookupQuerystring: "language",
      lookupCookie: "lng",
      lookupLocalStorage: "lng",
      // lookupFromPathIndex: 0,
      // lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      // excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: "myDomain",

      // optional htmlTag with lang attribute, the default is:
      // htmlTag: document.documentElement,

      // only detect languages that are in the whitelist
      // checkWhitelist: true
    },
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: loadPath,
      // allow cross domain requests
      crossDomain: true,
    },
    ns: [I18N_NS, COMMONS_NS, PRODUCTS_NS, FLIGHTS_NS, ACCOMMODATIONS_NS, COMBINED_NS],
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    saveMissing: process.env.NODE_ENV === "development" ? true : false,
    missingKeyHandler: missingKeyHandler,
    fallbackLng: "en",
  })

const rule = {
  name: "Español",
  numbers: [1, 2],
  plurals: function (n: number) {
    return Number(n !== 1)
  },
}

i18n.services.pluralResolver.addRule("es-AR", rule)
i18n.services.pluralResolver.addRule("es-EC", rule)
i18n.services.pluralResolver.addRule("es-CL", rule)
i18n.services.pluralResolver.addRule("es-UY", rule)
i18n.services.pluralResolver.addRule("es-PE", rule)

export default i18n
