import React from "react"
import styles from "./BottomNavigationBar.styles"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ListIcon from "@material-ui/icons/List"
import SwapVertIcon from "@material-ui/icons/SwapVert"
import FilterListIcon from "@material-ui/icons/FilterList"

export interface Props {
  viewMap: boolean
  onViewMap: () => void
  onViewSorting: () => void
  onViewFilters: () => void
}

const BottomNavigationBar: React.FC<Props> = ({ viewMap, onViewMap, onViewSorting, onViewFilters }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <BottomNavigation className={styles.nav} showLabels>
      <BottomNavigationAction
        onClick={onViewMap}
        className={styles.button(theme)}
        label={viewMap ? t("BottomNavigationBar.list") : t("BottomNavigationBar.map")}
        icon={viewMap ? <ListIcon /> : <LocationOnIcon />}
      />
      <BottomNavigationAction
        onClick={onViewSorting}
        className={styles.button(theme)}
        label={t("BottomNavigationBar.sort")}
        icon={<SwapVertIcon />}
      />
      <BottomNavigationAction
        className={styles.button(theme)}
        onClick={onViewFilters}
        label={t("BottomNavigationBar.filter")}
        icon={<FilterListIcon />}
      />
    </BottomNavigation>
  )
}

export default BottomNavigationBar
