import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  subtitle: css({
    fontSize: "14",
    fontWeight: 300,
    lineHeight: "1.14",
    color: "#4a4a4a",
    maxWidth: "568px",
  }),
  title: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "1.5",
      marginBottom: "8px",
    }),
  container: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 16px",
    gap: "16px",
    borderRadius: "3px",
    backgroundColor: "rgba(70, 128, 233, 0.12)",
    " >img": {
      width: "100px",
      height: "72px",
      marginRight: "16px",
    },
    " > div": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  }),
}

export default styles
