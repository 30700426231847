import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "24px",
    gap: "16px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24)",
    borderRadius: "8px",
  }),

  closeIconContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    width: "100%",
  }),

  closeIcon: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      cursor: "pointer",
    }),

  error: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px",
    alignItems: "center",
  }),

  title: (t: Theme) =>
    css({
      textAlign: "center",
      " > span": {
        color: t.colors.brand_darker,
      },
    }),

  text: css({
    textAlign: "center",
  }),

  button: css({
    marginTop: "16px",
    "&.MuiButton-root": {
      textTransform: "unset",
    },
  }),
}

export default styles
