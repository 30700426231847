import { SearchboxParams } from "@basset-la/components-combined/dist/models/searchbox"
import { mapFlightsParamsToFlightsApi } from "../utils/helpers/flights"
import { FlightPagination } from "../utils/types/flights"
import {
  Cluster as ClusterModel,
  FlightClusterFilters,
  FlightsClusterAvailableFilters,
  FetchError,
  MatrixModel,
  ChangeFareCluster,
  OrderByOptions,
  ItineraryRecommendations,
} from "@basset-la/components-flights/dist/model"
import { Cart } from "../utils/types/combined"
import { getSessionId } from "../utils/helpers/SessionStorage"
import { Config } from "@basset-la/components-commons"

export interface Price {
  adults: PassengerFare
  seniors?: PassengerFare
  children?: PassengerFare
  infants?: PassengerFare
  disabled?: PassengerFare
  charges: number
  taxes: number
  total: number
  currency: string
  vat_percentage?: number
  commision_rule_data: Commission
  over_commision_rule_data: Commission
  reseller_charges?: Charge
  agency_charges?: Charge
  agency_cost_effectiveness: number
  detail: object // @deprecated
}

export interface PassengerFare {
  fare: number
  taxes?: ChargeType[]
  quantity: number
}

export interface ChargeType {
  total: number
  type: string
}

export interface Charge {
  operating_cost: number
  fees: number
  markup: number
}

export interface Commission {
  currency: string
  ceded_amount: number
  airline_ceded_amount?: number
  agency_amount?: number
  reseller_amount: number
  airline_amount?: number
}

export interface FlightResponseModel {
  matrix: Array<MatrixModel>
  filters: FlightsClusterAvailableFilters
  clusters: Array<ClusterModel>
  recommendations: ItineraryRecommendations | null
  limit: number
  offset: number
  total: number
}

const createQueryString = (mapQs: any) => new URLSearchParams(mapQs).toString()

export const fetchFlightClusters = async (
  config: Config,
  cart: Cart,
  flightClusterQuery: SearchboxParams,
  filters: FlightClusterFilters,
  pagination: FlightPagination,
  orderBy: OrderByOptions,
  agentId: string | null
): Promise<FlightResponseModel> => {
  const filtersStr = "".concat(
    createQueryString({
      ...mapFlightsParamsToFlightsApi(flightClusterQuery, filters, pagination, orderBy),
      cart_id: cart.id,
      site: config.country,
      source: config.source,
      channel: "WEB",
    })
  )
  const url = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
    ? `${process.env.REACT_APP_COMBINED_PRODUCTS_API_URL}/flights/itineraries?${filtersStr}`
    : ""

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
    "x-client-id": config.agency_id,
    "x-session-id": getSessionId() || "",
  }
  if (agentId) {
    headers["x-user-id"] = agentId
  }

  const results = await fetch(url, {
    headers: headers,
  })
  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }

  return json
}

export const getCluster = async (
  config: Config,
  cart: Cart,
  id: string,
  agentId: string | null
): Promise<ChangeFareCluster> => {
  if (!process.env.REACT_APP_COMBINED_PRODUCTS_API_URL)
    throw new Error("Missing key REACT_APP_COMBINED_PRODUCTS_API_URL")

  const url = `${process.env.REACT_APP_COMBINED_PRODUCTS_API_URL}/flights/itineraries/${encodeURI(id)}?cart_id=${
    cart.id
  }&site=${config.country}&channel=WEB&source=`

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
    "x-client-id": config.agency_id,
  }
  if (agentId) {
    headers["x-user-id"] = agentId
  }

  const response = await fetch(url, {
    headers: headers,
  })
  const json = await response.json()

  if (response.ok || response.status === 400) {
    return json as ChangeFareCluster
  }

  throw new FetchError(response.status, json.error)
}
