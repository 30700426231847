import React, { useEffect, useReducer, useState } from "react"

import moment from "moment"
import qs from "qs"
import ReactGA from "react-ga"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router"

import Searchbox from "@basset-la/components-combined/dist/components/Searchbox"
import SearchboxMobile from "@basset-la/components-combined/dist/components/SearchboxMobile"
import Stepper from "@basset-la/components-combined/dist/components/Stepper"
import { SearchboxParams, StepType } from "@basset-la/components-combined/dist/models"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import { CellInfo } from "@basset-la/components-flights/dist/components/PricesMatrix"
import {
  AdvertisingInfo,
  Cluster as ClusterModel,
  FetchError,
  FlightClusterFilters,
  FlightsClusterAvailableFilters,
  OrderByOptions,
  Price,
  RecommendedItinerary,
} from "@basset-la/components-flights/dist/model"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { getFlightsAdvertising } from "../../../api/advertising"
import { getCart, updateCart } from "../../../api/combined"
import { fetchFlightClusters, getCluster } from "../../../api/flights"
import { getRegionsForFlights } from "../../../api/geo"
import { CLUSTERS_PER_PAGE, I18N_NS, NO_VALUE, ROUND_TRIP } from "../../../utils/constants"
import {
  addGA,
  getCartId,
  getEnabledStep,
  parseSearchboxParams,
  removeCartState,
  saveEnabledStep,
  searchParamsToCheckoutPath,
  searchParamsToFlightResultsPath,
  searchParamsToHotelResultsPath,
} from "../../../utils/helpers/common"
import {
  mapFlightsParamsToResultsUrl,
  parseAppliedFilters,
  parseOrderBy,
  parsePagination,
} from "../../../utils/helpers/flights"
import { Cart } from "../../../utils/types/combined"
import { FlightPagination } from "../../../utils/types/flights"
import Wrapper from "../../Wrapper"
import ErrorMessageWrapper from "../FlightsResults/ErrorMessageWrapper"
import FlightsResults from "../FlightsResults/FlightsResults"
import { flightResultsReducer } from "./reducer"
import { getAirlineAutocomplete } from "../../../api/autocomplete"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import ChangedFareDialog from "@basset-la/components-flights/dist/components/ChangedFareDialog"
import LinearProgress from "@basset-la/components-commons/dist/components/LinearProgress"
import Message from "@basset-la/components-commons/dist/components/Message"
import ClusterBrands from "@basset-la/components-flights/dist/components/ClusterBrands"
import { fillSelectedBrand } from "@basset-la/components-flights/dist/utils/helpers"
import ItineraryNotFoundDialog from "@basset-la/components-flights/dist/components/ItineraryNotFoundDialog"

interface BuyConfirmation {
  confirm: boolean
  data: {
    itineraryId: string
    selectedBrand: number
    arrivalDate: string
    price: Price
  } | null
}

interface ChangedFareData {
  flow: "brands" | "buy"
  oldPrice: Price
  newPrice: Price
}

interface SelectBrandData {
  idx: number
  cluster: ClusterModel
  selectedOptions: number[]
  selectedBrand: number
}

const Results: React.FC<{}> = () => {
  const { t, i18n } = useTranslation(I18N_NS)
  const { search } = useLocation()
  const history = useHistory()
  const { config } = useConfig()
  const { userId } = useAuthUser()

  const [cart, setCart] = useState<Cart | null>(null)
  const [advertising, setAdvertising] = useState<AdvertisingInfo>()
  const [searchParams, setSearchParams] = useState<SearchboxParams>(parseSearchboxParams(search)[0])
  const [appliedFilters, setAppliedFilters] = useState<FlightClusterFilters>(parseAppliedFilters(search))
  const [pagination, setPagination] = useState<FlightPagination>(parsePagination(search))
  const [orderBy, setOrderBy] = useState<OrderByOptions>(parseOrderBy(search))
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSelecting, setIsSelecting] = useState(false)
  const [lastRequestedPage, setLastRequestedPage] = useState(1)
  const [selectingError, setSelectingError] = useState<FetchError | null>()
  const [buyConfirmation, setBuyConfirmation] = useState<BuyConfirmation>({ confirm: false, data: null })
  const [openChangeFareDialog, setOpenChangeFareDialog] = useState(false)
  const [changedFareData, setChangedFareData] = useState<ChangedFareData | null>(null)
  const [isLoadingCluster, setIsLoadingCluster] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedBrandData, setSelectedBrandData] = useState<SelectBrandData | null>(null)
  const [openBrandDialog, setOpenBrandDialog] = useState(false)
  const [openItineraryNotFoundDialog, setOpenItineraryNotFoundDialog] = useState(false)

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [flightResults, dispatchFlightResults] = useReducer(flightResultsReducer, {
    clusters: [],
    matrix: [],
    selectedBrands: [],
    recommendations: null,
    filters: {} as FlightsClusterAvailableFilters,
    total: 0,
    viewAlert: false,
    error: null,
    showSelectedFlightAlert: false,
  })

  useEffect(() => {
    document.title = t("flights.Results.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
    saveEnabledStep("flight")
  }, [t])

  useEffect(() => {
    const loadCart = async () => {
      setIsLoading(true)
      const id = getCartId()
      try {
        const result = await getCart(config, id, searchParams)
        setCart(result)
      } catch (e) {
        console.log(e)
        setIsLoading(false)
      }
    }

    if (!cart) {
      loadCart()
    }
  }, [config, searchParams, cart])

  useEffect(() => {
    const loadAdvertising = async () => {
      try {
        const adv = await getFlightsAdvertising(config)
        setAdvertising(adv)
      } catch (e) {
        console.log(e)
      }
    }

    loadAdvertising()
  }, [config])

  useEffect(() => {
    const getClusters = async () => {
      try {
        const results = await fetchFlightClusters(
          config,
          cart!,
          searchParams,
          appliedFilters,
          pagination,
          orderBy,
          userId
        )
        return results
      } catch (e) {
        console.log(e)
      }
    }

    const loadClusters = async () => {
      try {
        setIsLoading(true)
        const results = await getClusters()
        if (pagination.page === 1) {
          dispatchFlightResults({ type: "set", cart: cart!, payload: results })
        } else {
          dispatchFlightResults({ type: "append", payload: results })
        }
      } catch (e) {
        console.error(e)
        dispatchFlightResults({ type: "error", payload: e as FetchError })
      } finally {
        setIsLoading(false)
      }
    }

    if (cart) {
      loadClusters()
    }
  }, [config, cart, pagination, searchParams, appliedFilters, orderBy, userId])

  const pushUrl = (
    sp: SearchboxParams,
    f: FlightClusterFilters,
    p: FlightPagination,
    o: OrderByOptions,
    reset: boolean = false
  ) => {
    const queryParams = qs.stringify(mapFlightsParamsToResultsUrl(sp, o, f, p))
    history.push({
      pathname: "/combined/flights",
      search: queryParams,
    })

    setSearchParams(sp)
    setAppliedFilters(f)
    setPagination(p)
    setOrderBy(o)
    setLastRequestedPage(1)
    if (reset) {
      dispatchFlightResults({ type: "reset_all" })
    } else {
      dispatchFlightResults({ type: "reset_clusters" })
    }
  }

  const handleSearch = (sp: SearchboxParams) => {
    const f: FlightClusterFilters = {
      airlines: sp.airlines?.length ? new Set(sp.airlines) : new Set(),
      stops: sp.stops !== undefined && sp.stops !== NO_VALUE ? new Set([sp.stops]) : new Set(),
      outboundDepartureAirports: new Set(),
      outboundArrivalAirports: new Set(),
      inboundDepartureAirports: new Set(),
      inboundArrivalAirports: new Set(),
      outboundDepartureTime: [],
      inboundDepartureTime: [],
      sourceType: new Set(),
    }
    const p: FlightPagination = {
      offset: 0,
      limit: CLUSTERS_PER_PAGE,
      page: 1,
    }
    setCart(null)
    removeCartState()
    pushUrl({ ...sp }, { ...f }, { ...p }, orderBy, true)
  }

  const handleClickMatrix = (item: CellInfo) => {
    const f: FlightClusterFilters = {
      airlines: item.airline !== undefined ? new Set([item.airline]) : new Set(),
      stops: item.stops !== undefined ? new Set([`${item.stops}`]) : new Set(),
      outboundDepartureAirports: new Set(),
      outboundArrivalAirports: new Set(),
      inboundDepartureAirports: new Set(),
      inboundArrivalAirports: new Set(),
      outboundDepartureTime: [],
      inboundDepartureTime: [],
      sourceType: new Set(),
    }
    pushUrl(searchParams, f, pagination, orderBy)
  }

  const handleSubmitFilters = (f: FlightClusterFilters) => {
    const p: FlightPagination = {
      offset: 0,
      limit: CLUSTERS_PER_PAGE,
      page: 1,
    }
    pushUrl(
      {
        ...searchParams,
        airlines: [...f.airlines],
      },
      { ...f },
      { ...p },
      orderBy
    )
  }

  const handlePaginatedSearch = () => {
    const { offset, limit, page } = pagination as FlightPagination
    if (!flightResults.total || flightResults.total > Number(offset) + Number(limit)) {
      const currentPage = page ? page + 1 : Number(offset) / CLUSTERS_PER_PAGE + 1
      if (currentPage > lastRequestedPage) {
        const p: FlightPagination = {
          offset: offset + (limit && limit > CLUSTERS_PER_PAGE ? limit : CLUSTERS_PER_PAGE),
          limit: CLUSTERS_PER_PAGE,
          page: currentPage,
        }

        setLastRequestedPage(currentPage)
        setPagination(p)
        setIsLoading(true)
      }
    }
  }

  useEffect(() => {
    const buy = async () => {
      try {
        setIsSelecting(true)

        await updateCart(config, "FLIGHT", {
          ...cart!,
          flight: {
            ...cart!.flight,
            flight_itinerary_id: buyConfirmation.data!.itineraryId,
            selected_brand: buyConfirmation.data!.selectedBrand,
          },
        })

        const lastEnabled = getEnabledStep()

        searchParams.leg.from = moment(buyConfirmation.data!.arrivalDate)

        let path =
          lastEnabled !== "checkout"
            ? searchParamsToHotelResultsPath(cart!.id, searchParams)
            : searchParamsToCheckoutPath(cart!.id, searchParams)

        path = addGA(search, path)

        if (lastEnabled !== "checkout") {
          history.push(path)
        } else {
          window.location.href = path
        }
      } catch (e) {
        console.error(e)
        setSelectingError(e as FetchError)
      } finally {
        setIsSelecting(false)
      }
    }

    if (!buyConfirmation.confirm || !buyConfirmation.data) return
    buy()
  }, [buyConfirmation, cart, config, history, search, searchParams])

  const handleAcceptNewFare = () => {
    switch (changedFareData!.flow) {
      case "buy":
        setBuyConfirmation({
          ...buyConfirmation,
          confirm: true,
        })
        break
      case "brands":
        setOpenBrandDialog(true)
        break
    }
    setOpenChangeFareDialog(false)
    setChangedFareData(null)
  }

  const handleRejectNewFare = () => {
    if (changedFareData!.flow === "brands") {
      dispatchFlightResults({ type: "reload_cluster", payload: { idx: selectedBrandData!.idx } })
      setSelectedBrandData(null)
    }

    setOpenChangeFareDialog(false)
    setChangedFareData(null)
  }

  const getItineraryID = (cluster: ClusterModel, selectedOptions: number[]): string => {
    let ids: string[] = []
    for (const i in cluster.segments) {
      ids.push(cluster.segments[i].options[selectedOptions[i]].id)
    }
    return ids.join("_")
  }

  const checkAndBuyItinerary = async (
    cluster: ClusterModel,
    itineraryId: string,
    arrivalDate: string,
    selectedBrand: number
  ) => {
    try {
      setIsLoadingCluster(true)
      setErrorMessage("")

      const itinerary = await getCluster(config, cart!, itineraryId, userId)

      const oldPrice = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price
      const newPrice = itinerary.upsell_fares ? itinerary.upsell_fares[selectedBrand].price : itinerary.price
      if (oldPrice.total - newPrice.total === 0) {
        setBuyConfirmation({
          confirm: true,
          data: {
            itineraryId: itineraryId,
            selectedBrand: selectedBrand,
            arrivalDate: arrivalDate,
            price: newPrice,
          },
        })
        return
      }

      setBuyConfirmation({
        confirm: false,
        data: {
          itineraryId: itineraryId,
          selectedBrand: selectedBrand,
          arrivalDate: arrivalDate,
          price: newPrice,
        },
      })
      setChangedFareData({
        flow: "buy",
        oldPrice: oldPrice,
        newPrice: newPrice,
      })
      setOpenChangeFareDialog(true)
      setIsLoadingCluster(false)
    } catch (err) {
      console.log(err)
      setIsLoadingCluster(false)
      if ((err as FetchError).statusCode === 404) {
        setOpenItineraryNotFoundDialog(true)
      } else {
        setErrorMessage(t("flights.Results.messages.fetchItineraryError"))
      }
    }
  }

  const handleBuy = async (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => {
    const cluster = { ...flightResults.clusters[clusterIndex] }
    const itineraryId = getItineraryID(cluster, selectedOptions)
    const arrivalDate = cluster.segments[0].options[0].arrival_date
    checkAndBuyItinerary(cluster, itineraryId, arrivalDate, selectedBrand)
  }

  const handleBuyItinerary = (_: RecommendedItinerary) => {
    // TODO: implement this for combined
  }

  const handleOpenBrandSelectionDialog = (
    cluster: ClusterModel,
    idx: number,
    selectedOptions: number[],
    selectedBrand: number
  ) => {
    const loadCluster = async () => {
      try {
        setIsLoadingCluster(true)
        setErrorMessage("")

        const opts = cluster.segments.map((_) => 0)
        const itineraryId = getItineraryID(cluster, opts)
        const itinerary = await getCluster(config, cart!, itineraryId, userId)

        if (!itinerary.upsell_fares) {
          setErrorMessage(t("flights.Results.messages.brandsNotFoundError"))
          return
        }

        let itinerarySelectedBrand = selectedBrand
        if (!cluster.upsell_fares) {
          /*
          const clusterBrandName = cluster.segments[0].options[0].legs
            .map(l => l.brand?.name || "")
            .reduce((prev, curr) => {
              return prev !== "" ? prev : curr
            }, "")
          */
          itinerarySelectedBrand = fillSelectedBrand(itinerary)
        }

        const itineraryPrice = itinerary.upsell_fares
          ? itinerary.upsell_fares[itinerarySelectedBrand].price
          : itinerary.price
        setSelectedBrandData({
          idx,
          selectedOptions,
          selectedBrand: itinerarySelectedBrand,
          cluster: {
            ...cluster,
            price: itineraryPrice,
            upsell_fares: itinerary.upsell_fares,
          },
        })

        const clusterPrice = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price
        if (clusterPrice.total - itineraryPrice.total === 0) {
          setOpenBrandDialog(true)
        } else {
          setChangedFareData({
            flow: "brands",
            oldPrice: clusterPrice,
            newPrice: itineraryPrice,
          })
          setOpenChangeFareDialog(true)
        }
      } catch (err) {
        console.error(err)
        if ((err as FetchError).statusCode === 404) {
          dispatchFlightResults({ type: "reload_cluster", payload: { idx } })
          setOpenItineraryNotFoundDialog(true)
        } else {
          setErrorMessage(t("flights.Results.messages.fetchItineraryError"))
        }
      } finally {
        setIsLoadingCluster(false)
      }
    }

    loadCluster()
  }

  const handleCloseBrandSelectionDialog = () => {
    dispatchFlightResults({ type: "reload_cluster", payload: { idx: selectedBrandData!.idx } })

    setSelectedBrandData(null)
    setOpenBrandDialog(false)
  }

  const handleSelectNewBrand = (brandIdx: number) => {
    dispatchFlightResults({
      type: "update_upsell_fares",
      payload: {
        idx: selectedBrandData!.idx,
        price: selectedBrandData!.cluster.price,
        upsellFares: selectedBrandData!.cluster.upsell_fares,
        selectedBrand: brandIdx,
      },
    })

    setSelectedBrandData(null)
    setOpenBrandDialog(false)
  }

  const handleBannerClick = (url: string) => {
    window.open(url, "_blank")?.focus()
  }

  const handleCloseOfferAlert = () => {
    dispatchFlightResults({ type: "hide_offer_alert" })
  }

  const handleCloseSelectedFlightAlert = () => {
    dispatchFlightResults({ type: "hide_selected_flight_alert" })
  }

  const handleStepClick = (type: StepType) => {
    let path = ""
    switch (type) {
      case "flight":
        path = searchParamsToFlightResultsPath(cart!.id, searchParams)
        break
      case "hotel":
        path = searchParamsToHotelResultsPath(cart!.id, searchParams)
        break
      case "checkout":
        path = searchParamsToCheckoutPath(cart!.id, searchParams)
        break
    }
    path = addGA(search, path)
    window.location.href = path
  }

  const getStepperComponent = () => {
    if (!cart) return <></>

    return (
      <Stepper
        accommodation={{
          hotelName: cart.accommodation.name,
          totalRooms: cart.accommodation.total_rooms,
        }}
        flight={{
          origin: cart.flight.origin,
          destination: cart.flight.destination,
          from: moment(cart.flight.from),
          to: moment(cart.flight.to),
          totalPassengers: cart.flight.total_passengers,
        }}
        stickyBoundary={{
          top: 0,
          bottom: "#clusterListContainer",
        }}
        currentStep="flight"
        enabledStep={getEnabledStep()}
        onStepClick={handleStepClick}
      />
    )
  }

  const handleCloseMessage = () => {
    setErrorMessage("")
  }

  const handleRefreshItineraries = () => {
    setOpenItineraryNotFoundDialog(false)
    handleSearch(searchParams)
  }

  const handleCloseItineraryNotFoundDialog = () => {
    setOpenItineraryNotFoundDialog(false)
  }

  const handleChangeOrder = (order: OrderByOptions) => {
    pushUrl(searchParams, appliedFilters, pagination, order)
  }

  const searchboxProps = {
    getRegions: getRegionsForFlights(i18n.language, config.agency_id, config.country),
    getAirlines: getAirlineAutocomplete(i18n.language, config.agency_id, config.country),
    config: {
      minDate: moment().add(config.search_configuration.min_days, "days"),
      maxDate: moment().add(config.search_configuration.max_days, "days"),
      minNights: 1,
      maxNights: config.search_configuration.range_days,
      maxRooms: 4,
      maxRoomCapacity: 8,
    },
    params: searchParams,
    onSearch: handleSearch,
  }

  if (selectingError) {
    return (
      <Wrapper>
        <ErrorMessageWrapper error={selectingError} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {isLoadingCluster && <LinearProgress />}
      {(isLoading || isSelecting) && (
        <ProductLoader
          is_mobile={isMobile}
          variant="WEB"
          product_variant={isSelecting ? "ACCOMMODATIONS_RESULT" : "FLIGHTS_RESULT"}
        />
      )}
      {!isLoading && !isSelecting && (
        <FlightsResults
          isLoading={false}
          flightType={ROUND_TRIP}
          advertising={advertising}
          cart={cart}
          appliedFilters={appliedFilters}
          orderBy={orderBy}
          stepperComponent={getStepperComponent()}
          flightResults={flightResults}
          searchboxComponent={<Searchbox {...searchboxProps} />}
          searchboxMobileComponent={<SearchboxMobile {...searchboxProps} />}
          onClickMatrix={handleClickMatrix}
          onFilter={handleSubmitFilters}
          onBannerClick={handleBannerClick}
          onCloseOfferAlert={handleCloseOfferAlert}
          onCloseSelectedFlightAlert={handleCloseSelectedFlightAlert}
          onPaginatedSearch={handlePaginatedSearch}
          onBuy={handleBuy}
          onBuyItinerary={handleBuyItinerary}
          onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog}
          onChangeOrder={handleChangeOrder}
        />
      )}
      {changedFareData && (
        <ChangedFareDialog
          open={openChangeFareDialog}
          isMobile={isMobile}
          closeOnlyUserAction={true}
          currency={changedFareData.newPrice.currency}
          newPrice={changedFareData.newPrice.total}
          oldPrice={changedFareData.oldPrice.total}
          handleAcceptNewFare={handleAcceptNewFare}
          handleRejectNewFare={handleRejectNewFare}
        />
      )}
      {selectedBrandData && (
        <ClusterBrands
          openDialog={openBrandDialog}
          cluster={selectedBrandData!.cluster}
          selectedOptions={selectedBrandData!.selectedOptions}
          selectedBrand={selectedBrandData!.selectedBrand}
          onChange={handleSelectNewBrand}
          onClose={handleCloseBrandSelectionDialog}
        />
      )}
      {openItineraryNotFoundDialog && (
        <ItineraryNotFoundDialog
          open={openItineraryNotFoundDialog}
          onClose={handleCloseItineraryNotFoundDialog}
          onSearch={handleRefreshItineraries}
        />
      )}
      <Message
        open={errorMessage !== ""}
        variant="snackbar"
        action="error"
        message={errorMessage}
        onClose={handleCloseMessage}
      />
    </Wrapper>
  )
}

export default Results
