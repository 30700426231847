import styles from "./NoRoomFoundErrorDialog.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import Button from "@basset-la/components-commons/dist/components/Button"
import { I18N_NS } from "../../../utils/constants"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import RoomNotAvailableIcon from "../../../icons/RoomNotAvailbableIcon"
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"

export interface Props {
  open: boolean
  onClose: () => void
  onGoBack: () => void
}

const NoRoomFoundErrorDialog = ({ open, onClose, onGoBack }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleGoBack = () => {
    onGoBack()
  }

  const handleCloseDialog = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      onClose()
    }
  }

  const handleClickCloseIcon = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <div className={styles.root}>
        <div className={styles.closeIconContainer} onClick={handleClickCloseIcon}>
          <CloseIcon className={styles.closeIcon(theme)} />
        </div>
        <RoomNotAvailableIcon />
        <div className={styles.error}>
          <div className={styles.title(theme)}>
            <Text size={24} variant="bold">
              {t("NoRoomFoundError.title")}
            </Text>
          </div>
          <div className={styles.text}>
            <Text size={14} variant="regular">
              {t("NoRoomFoundError.text")}
            </Text>
          </div>
          <div className={styles.button}>
            <Button variant="contained" onClick={handleGoBack}>
              {t("NoRoomFoundError.back")}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default NoRoomFoundErrorDialog
