import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  taxDisclamer: css({
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#9b9b9b",
    marginBottom: "8px",
  }),

  price: css({
    fontSize: "18px",
    lineHeight: "1.33",
    letterSpacing: "1px",
    color: "#000",
    " > span": {
      fontWeight: 500,
    },
  }),

  buttonContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "end",
  }),

  rate: (t: Theme, selected: boolean) =>
    css({
      border: "1px solid #eaeaea",
      borderRadius: "4px",
      padding: "16px 8px",
      cursor: "pointer",
      backgroundColor: selected ? `${t.colors.brand_primary}10` : t.colors.background_primary,
    }),

  ratesContainer: css({
    display: "flex",
    flexDirection: "column",
    " > div": {
      marginBottom: "8px",
    },
    " > div:last-child": {
      marginBottom: "0px",
    },
  }),

  roomsContainer: css({
    padding: "8px",
    " > div": {
      marginBottom: "16px",
    },
    " > div:last-child": {
      marginBottom: "0px",
    },
  }),

  roomCluster: css({
    padding: "8px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  }),

  roomClustersContainer: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
  }),

  rateInformation: css({
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1.71",
    margin: "0 8px",
  }),

  title: css({
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "1.33",
    margin: "0 8px",
  }),

  container: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#eaeaea",
  }),

  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        color: "#ffffff",
        backgroundColor: theme.colors.brand_primary,
      },
      "&.MuiButton-contained:hover": {
        backgroundColor: theme.colors.brand_primary,
      },
    }),
}

export default styles
