import { FetchError } from "@basset-la/components-flights/dist/model"

export const getRegion = async (id: string, type: string, language: string, clientId: string, country: string) => {
  if (!process.env.REACT_APP_GEO_URL) throw new Error("Missing key REACT_APP_GEO_URL")
  const url = process.env.REACT_APP_GEO_URL + `/regions?id=${id}&type=${type}&site=${country}&channel=WEB`

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": clientId,
    },
  })

  const json = await response.json()
  if (!response.ok) {
    throw new FetchError(response.status, json.error)
  }

  const region = json
  const key = language.split("-")[0]
  return {
    id: region.id,
    name: region.name ? region.name[key] : "",
    type: region.type,
  }
}

export const getEntities = async (iata: string, language: string, clientId: string) => {
  if (!process.env.REACT_APP_GEO_URL) throw new Error("Missing key REACT_APP_GEO_URL")

  const lang = language.split("-")[0]
  const url = process.env.REACT_APP_GEO_URL + `/entities?iata_code=${iata}&language=${lang}`

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": clientId,
    },
  })

  const json = await response.json()
  if (!response.ok) {
    throw new FetchError(response.status, json.error)
  }

  return json
}

export const getRegionsForFlights = (language: string, clientId: string, site: string) => async (value: string) => {
  try {
    const url =
      process.env.REACT_APP_AUTOCOMPLETE_API_URL +
      `?entities=CITY,AIRPORT&q=${encodeURIComponent(value)}&language=${
        language.split("-")[0]
      }&product=FLIGHTS&site=${site}&channel=WEB`

    const results = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
        "x-client-id": clientId,
      },
    })

    const json = await results.json()
    if (!results.ok) {
      throw new FetchError(results.status, json.error)
    }
    return json
  } catch (e) {}
  return []
}

export const getRegionsforAccommodations =
  (language: string, clientId: string, site: string) => async (value: string) => {
    try {
      const url =
        process.env.REACT_APP_AUTOCOMPLETE_API_URL +
        `?entities=ACC_MULTI_CITY_VICINITY,ACC_CITY,ACC_NEIGHBORHOOD,ACCOMMODATION&q=${encodeURIComponent(
          value
        )}&language=${language.split("-")[0]}&product=ACCOMMODATIONS&site=${site}&channel=WEB`

      const results = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
          "x-client-id": clientId,
        },
      })

      const json = await results.json()
      if (!results.ok) {
        throw new FetchError(results.status, json.error)
      }
      return json
    } catch (e) {}
    return []
  }
