import styles from "./HotelNotAvailableError.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import Button from "@basset-la/components-commons/dist/components/Button"
import { I18N_NS } from "../../../utils/constants"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import HotelNotAvailableIcon from "../../../icons/HotelNotAvailbableIcon"

export interface Props {
  onGoBack: () => void
}

const HotelNotAvailableError = ({ onGoBack }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleGoBack = () => {
    onGoBack()
  }

  return (
    <div className={styles.root}>
      <HotelNotAvailableIcon />
      <div className={styles.error}>
        <div className={styles.title(theme)}>
          <Text size={24} variant="bold">
            {t("HotelNotAvailableError.title")}
          </Text>
        </div>
        <div className={styles.text}>
          <Text size={18} variant="regular">
            {t("HotelNotAvailableError.text")}
          </Text>
        </div>
        <div className={styles.button}>
          <Button variant="contained" onClick={handleGoBack}>
            {t("HotelNotAvailableError.back")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default HotelNotAvailableError
