import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  matrix: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.background_primary,
      " > div": {
        padding: "0",
      },
      "@media(max-width:1024px)": {
        display: "none",
      },
    }),

  container: (t: Theme) =>
    css({
      display: "flex",
      padding: 0,
      flexDirection: "column",
      background: t.colors.background_variant,
      "@media(min-width:1024px)": {
        padding: "24px 0",
        flexDirection: "row",
        width: "100%",
      },
    }),

  leftContainer: css({
    flex: "1 1 auto",
    padding: 0,
    "@media(min-width:1024px)": {
      paddingLeft: "8px",
      width: "352px",
    },
  }),

  rightContainer: css({
    flex: "1 1 auto",
    padding: "0 8px 48px 8px",
    width: "100%",
    "@media(max-width:1024px)": {
      display: "flex",
      flexDirection: "column",
      padding: "0",
    },
  }),

  filters: css({
    marginTop: "8px",
  }),

  clusterListContainer: css({
    marginTop: 0,
    "@media(min-width:1024px)": {
      marginTop: "8px",
    },
  }),

  root: css({
    display: "flex",
    flexDirection: "column",
  }),

  progressStyle: (t: Theme) =>
    css({
      " > div": {
        backgroundColor: `${t.colors.brand_darker} !important`,
      },
    }),

  advertising: css({
    marginBottom: "16px",
  }),

  stepper: css({
    margin: "0px 8px 8px 8px",
    "@media(min-width: 1024px)": {
      margin: "0px 0px 16px 0px",
    },
  }),

  alertContainerStyle: css({
    paddingTop: "8px",
    paddingBottom: "16px",
  }),

  searchbox: css({
    "@media(max-width:1024px)": {
      display: "none",
    },
  }),

  mobSearchbox: css({
    margin: "0px 8px 8px 8px",
    "@media(min-width:1024px)": {
      display: "none",
    },
  }),
}

export default styles
