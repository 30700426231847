import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  button: (theme: Theme) =>
    css({
      color: `${theme.brand_colors.click_color} !important`,
    }),
  nav: css({
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    zIndex: 20,
    boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)",
    "&.MuiBottomNavigation-root": {
      justifyContent: "space-evenly",
    },
  }),
  filterIcon: css({
    margin: "3px 0px",
  }),
}

export default styles
