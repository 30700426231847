import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: "16px",
  }),

  icon: css({
    fontSize: "14px !important",
    marginRight: "5px",
  }),

  text: css({
    paddingTop: "4px",
  }),

  buttonContainer: css({
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  }),
}

export default styles
