import { Cart } from "../../../utils/types/combined"
import { AccommodationModel, ResultFilters, ResultSorting } from "../AccommodationResults/types"

export type HotelResults = {
  availableFilters: ResultFilters
  availableSortingOptions: ResultSorting
  accommodations: AccommodationModel[]
  totalAccommodations: number
  currencyCode: string
  viewAlert: boolean
  showSelectedHotelAlert: boolean
}

export type HotelResultsPayload = {
  results: any
  queries: any
}

export interface HotelResultsReducerAction {
  type: "set" | "append" | "clear" | "hide_offer_alert" | "hide_selected_hotel_alert"
  cart?: Cart
  payload?: HotelResultsPayload
}

export const HotelResultsInitialValue: HotelResults = {
  availableFilters: {
    amenities: [],
    category: [],
    price: [],
    stars: [],
    meal_plan: [],
    providers: [],
    regions: [],
  },
  availableSortingOptions: {
    options: [],
    selected: "",
  },
  accommodations: [],
  totalAccommodations: 0,
  currencyCode: "",
  viewAlert: false,
  showSelectedHotelAlert: false,
}

const getCurrencyCode = (accommodations: any[]): string => {
  if (accommodations && accommodations.length > 0 && accommodations[0].rates && accommodations[0].rates.length > 0) {
    return accommodations[0].rates[0].currency
  }
  return ""
}

const checkOfferAlert = (accommodations: AccommodationModel[], queries) => {
  const offerPrice = queries && queries.offer_price ? queries.offer_price : ""
  if (accommodations.length > 0 && offerPrice) {
    const fare = accommodations[0].rates[0].fare?.total || 0
    const formattedFare = fare.toFixed(2)
    if (offerPrice !== formattedFare) {
      return true
    }
  }
  return false
}

const checkSelectedHotelAlert = (accommodations: AccommodationModel[], cart: Cart) => {
  if (accommodations.length > 0) {
    return accommodations[0].id !== cart.accommodation.accommodation_id
  }
  return true
}

export const hotelReducer = (results: HotelResults, action: HotelResultsReducerAction): HotelResults => {
  switch (action.type) {
    case "clear":
      return HotelResultsInitialValue
    case "set":
      return {
        availableFilters: action.payload!.results.filters,
        availableSortingOptions: action.payload!.results.sorting,
        accommodations: action.payload!.results.clusters,
        totalAccommodations: action.payload!.results.total,
        currencyCode: getCurrencyCode(action.payload!.results.clusters),
        viewAlert: checkOfferAlert(action.payload!.results.clusters || [], action.payload!.queries),
        showSelectedHotelAlert: checkSelectedHotelAlert(action.payload!.results.clusters || [], action.cart!),
      }
    case "append":
      return {
        ...results,
        accommodations: [...results.accommodations, ...action.payload!.results.clusters],
      }
    case "hide_offer_alert":
      return { ...results, viewAlert: false }
    case "hide_selected_hotel_alert":
      return { ...results, showSelectedHotelAlert: false }
  }
}
