import React from "react"
import styles from "./AccommodationClusterPlaceholder.styles"

export interface Props {
  isMobile: boolean
}

const AccommodationClusterPlaceholder: React.FC<Props> = ({ isMobile }) => {
  return (
    <div className={styles.container(isMobile)}>
      <div className={[styles.animatedBg, styles.img(isMobile)].join(" ")} />
      <div className={styles.mainContent(isMobile)}>
        <div
          className={[styles.animatedBg, styles.loadingBar].join(" ")}
          style={{ width: "200px", marginBottom: "16px" }}
        />
        <div
          className={[styles.animatedBg, styles.loadingBar].join(" ")}
          style={{ width: "120px", marginBottom: "16px" }}
        />
        <div
          className={[styles.animatedBg, styles.loadingBar].join(" ")}
          style={{ width: "120px", marginBottom: "32px" }}
        />
        <div className={`${styles.dotsContainer}`}>
          <div
            className={[styles.animatedBg, styles.loadingBar].join(" ")}
            style={{ width: "16px", marginRight: "8px" }}
          />
          <div
            className={[styles.animatedBg, styles.loadingBar].join(" ")}
            style={{ width: "16px", marginRight: "8px" }}
          />
          <div
            className={[styles.animatedBg, styles.loadingBar].join(" ")}
            style={{ width: "16px", marginRight: "8px" }}
          />
        </div>
      </div>
      <div className={styles.priceContainer(isMobile)}>
        <div className={[styles.animatedBg, styles.loadingBar].join(" ")} style={{ width: "104px" }} />
        {!isMobile && <div className={[styles.animatedBg, styles.loadingBar].join(" ")} style={{ width: "124px" }} />}
      </div>
    </div>
  )
}

export default AccommodationClusterPlaceholder
