import React from "react"
import styles from "./NotAvailableError.styles"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import NotAvailableIcon from "../../../icons/NotAvailableIcon"

const NotAvailableError: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.container}>
      <NotAvailableIcon />
      <div>
        <div>
          <p className={styles.title(theme)}>{t("NotAvailableError.title")}</p>
          <p className={styles.subtitle}>{t("NotAvailableError.description")}</p>
        </div>
      </div>
    </div>
  )
}

export default NotAvailableError
