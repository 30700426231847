import { RoomClusterRate } from "@basset-la/components-accommodations/dist/model"
import PriceboxWeb from "@basset-la/components-products/dist/components/PriceboxWeb"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"

export interface Props {
  isMobile: boolean
  rate: RoomClusterRate
  taxesDisclaimers: string[]
}

const RateTotalWeb = ({ isMobile, rate, taxesDisclaimers }: Props) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <PriceboxWeb
      isMobile={isMobile}
      currency={rate.fare!.currency}
      total={{
        label: t("RateTotal.priceTitle"),
        value: rate.fare!.total,
      }}
      breakdown={[
        {
          label: t("RateTotal.total"),
          value: rate.fare!.total,
        },
      ]}
      taxesDisclaimers={taxesDisclaimers}
      strikethrough={rate.fare!.discount}
    />
  )
}

export default RateTotalWeb
