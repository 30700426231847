import { css } from "@emotion/css"

const styles = {
  loaderContainer: css({
    display: "flex",
    height: "100%",
    width: "100%",
    paddingTop: "96px",
    flexDirection: "column",
    alignItems: "center",
    "> .MuiCircularProgress-root": {
      color: "#979797",
    },
  }),

  totalContainer: css({
    flex: "1 1",
    width: "100%",
    marginLeft: "16px",
  }),

  mainContainer: css({
    backgroundColor: "#eaeaea",
    padding: "16px",
    display: "flex",
    flexDirection: "row",
  }),

  roomClustersContainer: css({
    display: "flex",
    flex: "3 3",
    flexDirection: "column",
    " > div": {
      marginBottom: "8px",
    },
    " > div:last-child": {
      marginBottom: 0,
    },
  }),

  container: css({
    display: "flex",
    flexDirection: "column",
  }),
}

export default styles
