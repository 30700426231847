import React from "react"
import { useTheme } from "@basset-la/themed-components"
import styles from "./ClusterList.styles"
import InfiniteScroll from "./InfiniteScroll"
import { Cluster as ClusterModel } from "@basset-la/components-flights/dist/model"
import { AdvertisingData } from "@basset-la/components-flights/dist/components/Advertising"
import ClusterCombinedWeb from "@basset-la/components-flights/dist/components/ClusterCombinedWeb"
import { Cart } from "../../../utils/types/combined"

interface Props {
  cart: Cart
  dataSource: ClusterModel[]
  selectedBrands: number[]
  advertising?: AdvertisingData
  isLoading: boolean
  onPaginatedSearch: () => void
  onSelectItinerary: (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => void
  onOpenBrandSelectionDialog: (c: ClusterModel, idx: number, selectedOptions: number[], selectedBrand: number) => void
}

const ClusterList: React.FC<Props> = ({
  dataSource,
  selectedBrands,
  isLoading,
  advertising,
  onSelectItinerary,
  onPaginatedSearch,
  onOpenBrandSelectionDialog,
}) => {
  const theme = useTheme()

  const handlePaginatedSearch = () => {
    onPaginatedSearch()
  }

  const handleOnSelectItinerary = (clusterIndex: number) => (selectedOptions: number[], selectedBrand: number) => {
    onSelectItinerary(clusterIndex, selectedOptions, selectedBrand)
  }

  const handleOpenBrandSelectionDialog =
    (cluster: ClusterModel, idx: number) => (selectedOptions: number[], selectedBrand: number) => {
      onOpenBrandSelectionDialog(cluster, idx, selectedOptions, selectedBrand)
    }

  return (
    <InfiniteScroll isLoading={isLoading} onPaginatedSearch={handlePaginatedSearch}>
      {dataSource.map((cluster: ClusterModel, i) => {
        return (
          <div key={i} className={styles.cluster(theme)}>
            <ClusterCombinedWeb
              cluster={cluster}
              selectedBrand={selectedBrands[i]}
              advertising={advertising}
              onSelectItinerary={handleOnSelectItinerary(i)}
              onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog(cluster, i)}
            />
          </div>
        )
      })}
    </InfiniteScroll>
  )
}

export default ClusterList
