import React, { useEffect, useRef, useState } from "react"
import styles from "./AccommodationResults.styles"
import { useTheme } from "@basset-la/themed-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Advertising, Alert, Text } from "@basset-la/components-commons"
import { I18N_NS } from "../../../utils/constants"
import { useTranslation } from "react-i18next"
import AccommodationFilters from "@basset-la/components-accommodations/dist/components/AccommodationFilters"
import ListHeader from "./ListHeader"
import SortingOptions from "@basset-la/components-accommodations/dist/components/SortingOptions"
import HotelListMap from "@basset-la/components-accommodations/dist/components/HotelListMap"
import BottomNavigationBar from "../BottomNavigationBar/BottomNavigationBar"
import HotelList from "./HotelList"
import { AccommodationAppliedFilters, AccommodationResultsModel } from "./types"
import { AdvertisingInfo } from "@basset-la/components-accommodations/dist/model"
import { Cart } from "../../../utils/types/combined"

export interface Props {
  isLoading: boolean
  isPaginating: boolean
  showAlert: boolean
  cart: Cart | null
  advertising: AdvertisingInfo | null
  results: AccommodationResultsModel
  appliedFilters: AccommodationAppliedFilters
  searchboxComponent: React.ReactNode
  searchboxMobileComponent: React.ReactNode
  stepperComponent: React.ReactNode
  onFilterChange: (filters: AccommodationAppliedFilters) => void
  onBannerClick: (url: string) => void
  onPagination: () => void
  onCloseOfferAlert: () => void
  onCloseSelectedHotelAlert: () => void
  onGoToDetail: (hotelID: string) => void
  onToggleMapView?: (isShown: boolean) => void
}

const AccommodationResults: React.FC<Props> = ({
  isLoading,
  isPaginating,
  showAlert,
  advertising,
  results,
  cart,
  appliedFilters,
  searchboxComponent,
  searchboxMobileComponent,
  stepperComponent,
  onFilterChange,
  onBannerClick,
  onCloseOfferAlert,
  onCloseSelectedHotelAlert,
  onPagination,
  onGoToDetail,
  onToggleMapView,
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const containerRef = useRef(null)

  const [progress, setProgress] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [viewFilters, setViewFilters] = useState(false)
  const [viewMap, setViewMap] = useState(false)
  const [viewSorting, setViewSorting] = useState(false)

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current!["clientWidth"] - 32)
    }
  }, [containerRef])

  useEffect(() => {
    const startTimer = () => {
      setProgress(0)
      const t = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 95)
        })
      }, 300)
      return t
    }

    const stopTimer = () => {
      setProgress(100)
      clearInterval(0)
    }

    if (isLoading) startTimer()
    else stopTimer()
  }, [isLoading])

  const handleViewFilters = () => {
    setViewFilters(true)
  }

  const handleCloseFilters = () => {
    setViewFilters(false)
  }

  const handleViewSorting = () => {
    setViewSorting(true)
  }

  const handleCloseSorting = () => {
    setViewSorting(false)
  }

  const handleViewMap = () => {
    const view = !viewMap
    setViewMap(view)
    if (onToggleMapView) {
      onToggleMapView(view)
    }
  }

  const handleCloseOfferAlert = () => {
    onCloseOfferAlert()
  }

  const handleCloseSelectedHotelAlert = () => {
    onCloseSelectedHotelAlert()
  }

  const handleBannerClick = (url: string) => {
    onBannerClick(url)
  }

  const handleFilterChange = (key: string, value: string[]) => {
    setViewFilters(false)
    const f = { ...appliedFilters }
    f.filters[key] = value
    onFilterChange(f)
  }

  const handleSortingChange = (key: string, value: string[]) => {
    setViewSorting(false)
    const f = { ...appliedFilters }
    f.order_by = value.length > 0 ? value[0] : ""
    onFilterChange(f)
  }

  const handlePagination = () => {
    onPagination()
  }

  const handleGoToDetail = (hotelID: string) => {
    onGoToDetail(hotelID)
  }

  const showResults = () => {
    return results.accommodations && results.accommodations.length > 0
  }

  return (
    <>
      {isLoading && <LinearProgress className={styles.progressStyle(theme)} variant="determinate" value={progress} />}
      <div className={styles.backgroundStyle(theme)}>
        <div className={styles.containerStyle}>
          <div className={styles.filterContainerStyle}>
            <div className={styles.searchbox}>{searchboxComponent}</div>
            {showResults() && (
              <div id="filters">
                <AccommodationFilters
                  isMobile={isMobile}
                  openDialog={viewFilters}
                  onCloseDialog={handleCloseFilters}
                  availableFilters={{
                    ...results.availableFilters,
                    // we set providers to undefined here to avoid it being rendered by the AccommodationFilters component
                    providers: undefined,
                  }}
                  appliedFilters={appliedFilters.filters}
                  currencyCode={results.currencyCode}
                  onFilterChange={handleFilterChange}
                />
              </div>
            )}
          </div>

          <div ref={containerRef} className={styles.hotelListStyle}>
            {showResults() &&
              advertising &&
              advertising.top &&
              advertising.top.banners.length > 0 &&
              containerWidth > 0 && (
                <div className={styles.advertising}>
                  <Advertising width={`${containerWidth}px`} banner={advertising.top} onClick={handleBannerClick} />
                </div>
              )}
            <div className={styles.stepper}>{stepperComponent}</div>
            <div className={styles.mobSearchbox}>{searchboxMobileComponent}</div>
            {showAlert && (
              <div className={styles.alertContainer}>
                <Alert onClose={handleCloseOfferAlert}>
                  <Text size={14} variant="regular">
                    {t("AccommodationResult.notAvailableOfferAlert")}
                  </Text>
                </Alert>
              </div>
            )}
            {!isLoading && results.showSelectedOfferAlert && (
              <div className={styles.alertContainer}>
                <Alert onClose={handleCloseSelectedHotelAlert}>
                  <Text size={14} variant="regular">
                    {t("AccommodationResult.notAvailableCart")}
                  </Text>
                </Alert>
              </div>
            )}

            {showResults() && (
              <div id="listHeader">
                <ListHeader
                  isMobile={isMobile}
                  hotelsFound={results.totalElements}
                  viewMap={viewMap}
                  onViewMap={handleViewMap}
                  sortingComponent={
                    <SortingOptions
                      isMobile={isMobile}
                      availableSortingOptions={results.availableSortingOptions}
                      appliedSortingOption={appliedFilters.order_by || cart!.configuration.accommodation_sorting}
                      openDialog={viewSorting}
                      onCloseDialog={handleCloseSorting}
                      onFilterChange={handleSortingChange}
                    />
                  }
                />
              </div>
            )}
            {viewMap && showResults() && (
              <HotelListMap
                nights={results.nights}
                top={"#listHeader"}
                bottomBoundary={"#filters"}
                onHotelSelect={handleGoToDetail}
                accommodations={results.accommodations}
                onViewMoreHotels={() => handlePagination}
                viewPagination={results.totalElements > results.pageSize}
              />
            )}

            {!viewMap && (
              <HotelList
                accommodations={results.accommodations || []}
                isPaginating={isPaginating}
                isLoading={isLoading}
                total={results.totalElements || 0}
                onPagination={handlePagination}
                onHotelSelect={handleGoToDetail}
                nights={results.nights || 0}
                totalGuests={results.totalGuests || 0}
              />
            )}
            {showResults() &&
              advertising &&
              advertising.bottom &&
              advertising.bottom.banners.length > 0 &&
              containerWidth > 0 && (
                <div className={styles.advertising}>
                  <Advertising width={`${containerWidth}px`} banner={advertising.bottom} onClick={handleBannerClick} />
                </div>
              )}
          </div>

          {isMobile && (
            <BottomNavigationBar
              onViewFilters={handleViewFilters}
              onViewSorting={handleViewSorting}
              onViewMap={handleViewMap}
              viewMap={viewMap}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AccommodationResults
