import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  mobileHeaderContainer: css({
    display: "flex",
    flexDirection: "column",
  }),

  mobileItemContainer: css({
    padding: "8px",
  }),

  mainContainer: css({
    "@media(min-width:1200px),": {
      margin: "0 auto",
      maxWidth: "1200px",
      paddingBottom: "24px",
    },
  }),

  background: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_variant,
      minHeight: "100vh",
    }),

  stepper: css({
    marginBottom: "16px",
    "@media(max-width:1024px),": {
      marginBottom: "8px",
    },
  }),

  alertContainer: css({
    paddingTop: "8px",
    paddingBottom: "16px",
  }),
}

export default styles
