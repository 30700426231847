import React from "react"
import styles from "./HotelList.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import NotAvailableError from "./NotAvailableError"
import AccommodationClusterPlaceholder from "../AccommodationClusterPlaceholder/AccommodationClusterPlaceholder"
import AccommodationClusterCombinedWeb from "@basset-la/components-accommodations/dist/components/AccommodationClusterCombinedWeb"
import { calculateHighlightedAmenities } from "@basset-la/components-accommodations/dist/utils/helpers"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { AccommodationModel } from "./types"
import { MealPlan } from "@basset-la/components-accommodations/dist/model"
import InfiniteScroll from "./InfiniteScroll"

interface Props {
  isLoading: boolean
  isPaginating: boolean
  accommodations: AccommodationModel[]
  total: number
  nights: number
  totalGuests?: number
  onHotelSelect: (id: string) => void
  onPagination: () => void
}

const HotelList: React.FC<Props> = ({
  accommodations,
  onHotelSelect,
  isPaginating,
  onPagination,
  nights,
  totalGuests,
  isLoading,
  total,
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const rowRenderer = (acc: AccommodationModel, index: number) => {
    const rate = acc.rates[0]
    const taxesDisclaimers = []
    taxesDisclaimers.push(t("HotelList.taxes_included"))

    const paisTax = rate.fees ? rate.fees.find((f) => f.type === "PAIS") : undefined
    if (paisTax) taxesDisclaimers.push(t("HotelList.pais_tax"))

    const rg4815Tax = rate.fees ? rate.fees.find((f) => f.type === "RG4815/20") : undefined
    if (rg4815Tax) taxesDisclaimers.push(t("HotelList.rg4815_tax"))

    const rg5272Tax = rate.fees ? rate.fees.find((f) => f.type === "RG5272/22") : undefined
    if (rg5272Tax) taxesDisclaimers.push(t("HotelList.rg5272_tax"))

    return (
      <div key={index}>
        <AccommodationClusterCombinedWeb
          isMobile={isMobile}
          onViewHotel={() => onHotelSelect(acc.id)}
          address={acc.address}
          name={acc.name}
          guests={totalGuests!}
          nights={nights}
          photo={acc.images && acc.images.length > 0 ? acc.images[0].url : null}
          stars={acc.stars}
          {...calculateHighlightedAmenities(acc.amenities || [])}
          mealPlan={rate.meal_plan as MealPlan}
          taxesDisclaimers={taxesDisclaimers}
          accommodationFare={rate.fare!}
        />
      </div>
    )
  }

  const handlePagination = () => {
    onPagination()
  }

  let hasMore = total > accommodations.length

  if (!isLoading && accommodations && accommodations.length === 0) {
    return <NotAvailableError />
  }

  return (
    <div id="clusterListContainer" className={styles.list(theme)}>
      <InfiniteScroll isLoading={isLoading || isPaginating || !hasMore} onPaginatedSearch={handlePagination}>
        {!isLoading && accommodations.map((acc, idx) => rowRenderer(acc, idx))}
        {(isLoading || isPaginating) && (
          <>
            <AccommodationClusterPlaceholder isMobile={isMobile} />
            <AccommodationClusterPlaceholder isMobile={isMobile} />
            <AccommodationClusterPlaceholder isMobile={isMobile} />
          </>
        )}
      </InfiniteScroll>
    </div>
  )
}

export default HotelList
