import * as React from "react"
import styles from "./RoomsHeader.styles"
import Sticky from "react-stickynode"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"

interface Props {
  bottomBoundary?: string | number
  top?: string | number
}

const RoomsHeader = ({ bottomBoundary, top }: Props) => {
  const { t } = useTranslation(I18N_NS)
  return (
    <Sticky innerZ={150} top={top} bottomBoundary={bottomBoundary}>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.rooms}>{t("RoomsHeader.rooms")}</div>
          <div className={styles.rates}>
            <div>{t("RoomsHeader.options")}</div>
            <div>{t("RoomsHeader.choose")}</div>
          </div>
        </div>
        <div className={styles.price}>{t("RoomsHeader.total")}</div>
      </div>
    </Sticky>
  )
}

export default RoomsHeader
