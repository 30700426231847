import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  list: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      backgroundColor: t.colors.background_variant,
    }),
}

export default styles
