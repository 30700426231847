import { SearchboxParams } from "@basset-la/components-combined/dist/models/searchbox"
import _ from "underscore"
import { FlightPagination } from "../types/flights"
import { DEFAULT_DEPARTURE_TIME } from "@basset-la/components-flights/dist/components/FlightsFilters"
import { FlightClusterFilters, OrderByOptions } from "@basset-la/components-flights/dist/model"
import { NO_VALUE, CLUSTERS_PER_PAGE } from "../constants"
import { getAdults, getChildren, mapSearchboxParamsToUrl } from "./common"

const minutesToDuration = (minutes: number) => {
  let hh: any = Math.floor(minutes / 60)
  let mm: any = minutes % 60
  hh = hh < 10 ? `0${hh}` : hh
  mm = mm < 10 ? `0${mm}` : mm
  return `${hh}:${mm}`
}

const mapQueryStringToState = (qString: string) => {
  const qsArray: Array<any> = Array.from(new URLSearchParams(qString).entries())
  return qsArray.reduce((mapQs, qs) => ({ ...mapQs, [qs[0]]: qs[1] }), {})
}

const departureQueryToMinutes = (timeQS: string): number[] | null => {
  if (!timeQS) return null

  const timesArray = timeQS.split(",")
  const initRange = timesArray[0].split(":")
  const endRange = timesArray[1].split(":")
  const initRangeMinutes = Number(initRange[0]) * 60 + Number(initRange[1])
  const endRangeMinutes = Number(endRange[0]) * 60 + Number(endRange[1]) + 1
  return [initRangeMinutes, endRangeMinutes]
}

export const parseAppliedFilters = (queryString: string): FlightClusterFilters => {
  const mappedQs: any = mapQueryStringToState(queryString)
  return {
    airlines: mappedQs.airlines ? new Set(mappedQs.airlines.split(",")) : new Set(),
    stops:
      mappedQs.stops && mappedQs.stops !== "NO_VALUE"
        ? new Set(mappedQs.stops.split(",").map((stop: any) => Number(stop)))
        : new Set(),
    outboundDepartureAirports: mappedQs.outbound_departure_airports
      ? new Set(mappedQs.outbound_departure_airports.split(","))
      : new Set(),
    outboundArrivalAirports: mappedQs.outbound_arrival_airports
      ? new Set(mappedQs.outbound_arrival_airports.split(","))
      : new Set(),
    inboundDepartureAirports: mappedQs.inbound_departure_airports
      ? new Set(mappedQs.inbound_departure_airports.split(","))
      : new Set(),
    inboundArrivalAirports: mappedQs.inbound_arrival_airports
      ? new Set(mappedQs.inbound_arrival_airports.split(","))
      : new Set(),
    outboundDepartureTime: departureQueryToMinutes(mappedQs.outbound_departure_time) || DEFAULT_DEPARTURE_TIME,
    inboundDepartureTime: departureQueryToMinutes(mappedQs.inbound_departure_time) || DEFAULT_DEPARTURE_TIME,
    sourceType: mappedQs.source_type ? new Set(mappedQs.source_type.split(",")) : new Set(),
  }
}

export const parsePagination = (queryString: string): FlightPagination => {
  const mappedQs: any = mapQueryStringToState(queryString)
  return {
    offset: 0,
    limit: mappedQs.page ? CLUSTERS_PER_PAGE * Number(mappedQs.page) : CLUSTERS_PER_PAGE,
    page: mappedQs.page ? Number(mappedQs.page) : 1,
  }
}

export const parseOrderBy = (queryString: string): OrderByOptions => {
  const mappedQs: any = mapQueryStringToState(queryString)
  return mappedQs.order_by || "price"
}

const filtersToApiQueryString = (filters: FlightClusterFilters) => {
  return {
    ...(filters.stops && filters.stops.size > 0 ? { stops: [...filters.stops].join(",") } : {}),
    ...(filters.airlines && filters.airlines.size > 0 ? { airlines: [...filters.airlines].join(",") } : {}),
    ...(filters.outboundDepartureAirports && filters.outboundDepartureAirports.size > 0
      ? { outbound_departure_airports: [...filters.outboundDepartureAirports].join(",") }
      : {}),
    ...(filters.outboundArrivalAirports && filters.outboundArrivalAirports.size > 0
      ? { outbound_arrival_airports: [...filters.outboundArrivalAirports].join(",") }
      : {}),
    ...(filters.inboundDepartureAirports && filters.inboundDepartureAirports.size > 0
      ? { inbound_departure_airports: [...filters.inboundDepartureAirports].join(",") }
      : {}),
    ...(filters.inboundArrivalAirports && filters.inboundArrivalAirports.size > 0
      ? { inbound_arrival_airports: [...filters.inboundArrivalAirports].join(",") }
      : {}),
    ...(filters.outboundDepartureTime &&
    filters.outboundDepartureTime.length > 0 &&
    !_.isEqual(filters.outboundDepartureTime, DEFAULT_DEPARTURE_TIME)
      ? {
          outbound_departure_time: [
            minutesToDuration(filters.outboundDepartureTime[0]),
            minutesToDuration(filters.outboundDepartureTime[1] - 1),
          ].join(","),
        }
      : {}),
    ...(filters.inboundDepartureTime &&
    filters.inboundDepartureTime.length > 0 &&
    !_.isEqual(filters.inboundDepartureTime, DEFAULT_DEPARTURE_TIME)
      ? {
          inbound_departure_time: [
            minutesToDuration(filters.inboundDepartureTime[0]),
            minutesToDuration(filters.inboundDepartureTime[1] - 1),
          ].join(","),
        }
      : {}),
  }
}

export const mapFlightsParamsToFlightsApi = (
  searchParams: SearchboxParams,
  filters: FlightClusterFilters,
  pagination: FlightPagination,
  orderBy: OrderByOptions
) => {
  const obj = {
    dates: searchParams.leg.to
      ? searchParams.leg.from
        ? [searchParams.leg.from.format("YYYY-MM-DD"), searchParams.leg.to.format("YYYY-MM-DD")]
        : searchParams.leg.from
      : searchParams.leg.from
      ? searchParams.leg.from.format("YYYY-MM-DD")
      : searchParams.leg.from,
    from: (searchParams.leg.origin?.iata_code! || "")
      .concat(",")
      .concat(searchParams.leg.destination?.iata_code! || ""),
    to: (searchParams.leg.destination?.iata_code! || "").concat(",").concat(searchParams.leg.origin?.iata_code! || ""),
    adults: getAdults(searchParams.rooms),
    children: getChildren(searchParams.rooms),
    infants: 0, // TODO: No way to know?
    ...(searchParams.flightClass && searchParams.flightClass !== NO_VALUE ? { cabin: searchParams.flightClass } : {}),
    ...(searchParams.includeBaggage ? { checked_baggage: searchParams.includeBaggage } : {}),
    disabled: 0,
    seniors: 0,
    ...filtersToApiQueryString(filters),
    // stops: searchParams.stops || constants.NO_VALUE,
    offset: pagination.offset,
    limit: pagination.limit,
    order_by: orderBy,
  }
  obj.stops = obj.stops || searchParams.stops || NO_VALUE
  if (!obj.airlines && searchParams.airlines && searchParams.airlines!.length > 0) {
    obj.airlines = searchParams.airlines!.join(",")
  }
  return obj
}

export const mapFlightsParamsToResultsUrl = (
  searchParams: SearchboxParams,
  orderBy: OrderByOptions,
  filters: FlightClusterFilters | null = null,
  pagination: FlightPagination | null = null
) => {
  const urlParams = {
    ...(filters ? filtersToApiQueryString(filters) : {}),
    ...(pagination && pagination.page ? { page: pagination.page } : {}),
    ...mapSearchboxParamsToUrl(searchParams),
    order_by: orderBy,
  }
  return urlParams
}
