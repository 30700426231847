import { AdvertisingInfo } from "@basset-la/components-accommodations"
import { Config } from "@basset-la/components-commons"

const getAdvertisingFromUrl = async (config: any, type: string): Promise<AdvertisingInfo> => {
  const url = process.env.REACT_APP_ADVERTISING_API_URI
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ADVERTISING_API_URI")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  const res = await fetch(`${url}/banners/${type}?channel=WEB&site=${config.country}&source=${config.source}`, {
    headers: {
      "x-client-id": config.agency_id,
      "x-api-key": apiKey,
    },
  })
  if (res.ok) {
    const result = (await res.json()) as AdvertisingInfo
    return result
  }
  throw new Error("Failed to get advertising")
}

const getAdvertising = async (config: Config, type: string): Promise<AdvertisingInfo> => {
  return await getAdvertisingFromUrl(config, type)
}

export const getFlightsAdvertising = async (config: Config) => getAdvertising(config, "FLIGHTS_RESULTS")

export const getAccommodationsAdvertising = async (config: Config) => getAdvertising(config, "ACCOMMODATIONS_RESULTS")
