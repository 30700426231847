import { css, keyframes } from "@emotion/css"

const placeholderShimmer = keyframes({
  "0%": {
    backgroundPosition: "-468px 0",
  },
  "100%": {
    backgroundPosition: "468px 0",
  },
})

const styles = {
  priceContainer: (isMobile: boolean) =>
    css({
      display: "flex",
      flexDirection: isMobile ? "row" : "column",
      flexShrink: 0,
      flexGrow: 0,
      alignItems: "center",
      minWidth: !isMobile ? "200px" : undefined,
      borderTop: isMobile ? "1px solid #eaeaea" : undefined,
      justifyContent: isMobile ? "flex-end" : "space-between",
      padding: isMobile ? "16px 8px" : "16px 0px",
    }),

  loadingBar: css({
    height: "16px",
    borderRadius: "8px",
  }),

  dotsContainer: css({
    display: "flex",
    flexDirection: "row",
  }),

  mainContent: (isMobile: boolean) =>
    css({
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      padding: isMobile ? "8px" : "16px 16px 24px 16px",
    }),

  container: (isMobile: boolean) =>
    css({
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      borderRadius: "3px",
      backgroundColor: "#fff",
      //margin: "8px 0",
      height: !isMobile ? "200px" : undefined,
      width: !isMobile ? "100%" : undefined,
    }),

  img: (isMobile: boolean) =>
    css({
      width: "100%",
      flex: !isMobile ? "1 1 auto" : undefined,
      height: "200px",
      minWidth: !isMobile ? "304px" : undefined,
      maxWidth: !isMobile ? "304px" : undefined,
    }),

  animatedBg: css({
    animationDuration: "2s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: `${placeholderShimmer}`,
    animationTimingFunction: "linear",
    background: "linear-gradient(to right, #dedede 8%, #ccc 18%, #dedede 33%)",
    backgroundSize: "800px 104px",
  }),
}

export default styles
