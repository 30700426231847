import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  cluster: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_primary,
      "@media(max-width:1024px)": {
        margin: "16px 8px",
      },
    }),
}

export default styles
