import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  title: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "1.5",
    }),

  container: css({
    display: "flex",
    flexDirection: "column",
    " > *": {
      marginBottom: "8px",
    },
    " > *:last-child": {
      marginBottom: "8px",
    },
  }),

  roomAmount: css({
    borderRadius: "3px",
    fontWeight: 300,
    backgroundColor: "#f0f0f0",
    padding: "1px 8px",
  }),

  capacity: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  }),

  person: css({
    color: "#4a4a4a !important",
    height: "16px",
    width: "16px",
  }),

  bed: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "1.14",
    color: "#4a4a4a",
    fontWeight: 300,
    "&:last-child": {
      marginBottom: 0,
    },
  }),

  bedContainer: css({
    display: "flex",
    flexDirection: "column",
  }),
}

export default styles
