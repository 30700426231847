import styles from "./RoomDescription.styles"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import PersonIcon from "@material-ui/icons/Person"
import { useTheme } from "@basset-la/themed-components"
import TwinBedIcon from "../../../icons/TwinBedIcon"
import StandardBedIcon from "../../../icons/StandardBedIcon"

interface Props {
  amount: number
  name: string
  beds: {
    type: string
    size: string
    count: number
  }[]
  capacity: number
}

const RoomDescription = ({ amount, name, beds, capacity }: Props) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.container}>
      <div className={styles.title(theme)}>{amount > 1 ? `${amount} x ${name}` : `${name}`}</div>
      <div className={styles.bedContainer}>
        {beds.map((e, i) => (
          <div key={i} className={styles.bed}>
            {Array.apply(null, Array(e.count)).map(() => (
              <>
                {e.size === "Twin" && <TwinBedIcon />}
                {e.size !== "Twin" && <StandardBedIcon />}
              </>
            ))}
            <span> {`${t(`RoomDescription.bedTypes.${e.type}`, { count: e.count })}`}</span>
          </div>
        ))}
      </div>
      <div className={styles.capacity}>
        <span>{capacity}</span>
        <PersonIcon style={{ width: "16px" }} />
        <div className={styles.roomAmount}>{t("RoomDescription.for_room", { count: amount })}</div>
      </div>
    </div>
  )
}

export default RoomDescription
