import * as React from "react"
import styles from "./RoomCluster.styles"
import RoomDescription from "./RoomDescription"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import IconButton from "@material-ui/core/IconButton"
import { RoomClusterRate, RoomClusterRoom } from "@basset-la/components-accommodations/dist/model"
import { useTheme } from "@basset-la/themed-components"
import RateDetail from "@basset-la/components-accommodations/dist/components/RateDetail"

interface Props {
  rooms: RoomClusterRoom[]
  rates: RoomClusterRate[]
  clusterIndex: number
  onRateSelect: (clusterId: number, rateIndex: number) => void
}

const RoomCluster: React.FC<Props> = ({ rooms, rates, clusterIndex, onRateSelect }: Props) => {
  const theme = useTheme()

  const roomMap = {}
  rooms.forEach((r) => {
    if (!roomMap[r.id]) {
      roomMap[r.id] = [r]
    } else {
      roomMap[r.id].push(r)
    }
  })

  let k = 0
  const roomsToRender: any[] = []
  for (let key in roomMap) {
    const room = roomMap[key][0]
    roomsToRender.push(
      <RoomDescription
        key={`${clusterIndex}-${k++}`}
        amount={roomMap[key].length}
        beds={room.bed_configuration}
        capacity={room.capacity}
        name={room.name}
      />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.roomsDescriptionContainer}>{roomsToRender}</div>
      <div className={styles.ratesContainer}>
        {rates.map((r, i) => {
          return (
            <div
              key={i}
              className={styles.rateContainer(theme, r.selected || false)}
              onClick={() => onRateSelect(clusterIndex, i)}
            >
              <div className={styles.rateDetailContainer}>
                <RateDetail rate={r} />
              </div>
              <div className={styles.buttonContainer}>
                <IconButton>
                  {r.selected ? (
                    <CheckCircleIcon className={styles.icon(theme.colors.brand_primary)} />
                  ) : (
                    <CheckCircleOutlineIcon className={styles.icon("rgba(0, 0, 0, 0.54)")} />
                  )}
                </IconButton>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RoomCluster
