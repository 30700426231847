import React, { useEffect, useState } from "react"
import animateScrollTo from "animated-scroll-to"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga"
import { getAccommodationInformation, getRoomClusterById, getRoomClusters } from "../../../api/accommodations"
import Wrapper from "../../Wrapper"
import { I18N_NS } from "../../../utils/constants"
import styles from "./Details.styles"
import { useTheme } from "@basset-la/themed-components"
import { getRegionsForFlights } from "../../../api/geo"
import AccommodationDetail from "@basset-la/components-accommodations/dist/components/AccommodationDetail"
import RoomClusters from "../RoomClusters/RoomClusters"
import moment, { Moment } from "moment"
import AccommodationDetailSummary from "@basset-la/components-accommodations/dist/components/AccommodationDetailSummary"
import { AccommodationCatalog, RoomClusterRate, RoomCluster } from "@basset-la/components-accommodations/dist/model"
import { SearchboxRoom } from "@basset-la/components-accommodations/dist/components/Searchbox"
import Searchbox from "@basset-la/components-combined/dist/components/Searchbox"
import Dialog from "@material-ui/core/Dialog"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import {
  addGA,
  getCartId,
  getEnabledStep,
  getGuests,
  parseSearchboxParams,
  removeCartState,
  saveEnabledStep,
  searchParamsToCheckoutPath,
  searchParamsToFlightResultsPath,
  searchParamsToNewFlightResultsPath,
} from "../../../utils/helpers/common"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import RateTotalWeb from "../RateTotalWeb/RateTotalWeb"
import HotelNotAvailableError from "../HotelNotAvailableError/HotelNotAvailableError"
import { SearchboxParams, StepType } from "@basset-la/components-combined/dist/models"
import { searchParamsToHotelResultsPath } from "../../../utils/helpers/common"
import Stepper from "@basset-la/components-combined/dist/components/Stepper"
import { Cart } from "../../../utils/types/combined"
import { getCart, updateCart } from "../../../api/combined"
import Alert from "@basset-la/components-commons/dist/components/Alert"
import Text from "@basset-la/components-commons/dist/components/Text"
import RoomNotAvailableError from "../RoomNotAvailableError/RoomNotAvailableError"
import NoRoomFoundErrorDialog from "../NoRoomFoundErrorDialog/NoRoomFoundErrorDialog"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import { getAirlineAutocomplete } from "../../../api/autocomplete"
import { useConfig } from "@basset-la/components-commons"

interface MatchProps {
  hotelId: string
}

const Details: React.FC = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const { config } = useConfig()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch<MatchProps>()
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [sp] = parseSearchboxParams(location.search)

  const [searchParams] = useState(sp)
  const [cart, setCart] = useState<Cart | null>(null)
  const [checkin, setCheckin] = useState<Moment | null>(null)
  const [checkout, setCheckout] = useState<Moment | null>(null)
  const [accommodation, setAccommodation] = useState<AccommodationCatalog | null>(null)
  const [roomClusters, setRoomClusters] = useState<RoomCluster[]>([])
  const [isFetchingContent, setIsFetchingContent] = useState(false)
  const [isFetchingRates, setIsFetchingRates] = useState(true)
  const [totalGuests, setTotalGuests] = useState(0)
  const [roomNotAvailableForCheckout, setRoomNotAvailableForCheckout] = useState(false)
  const [rooms, setRooms] = useState<SearchboxRoom[]>([])
  const [selectedRate, setSelectedRate] = useState<RoomClusterRate | null>(null)
  const [showRoomAlert, setShowRoomAlert] = useState(true)
  const [showSearchbox, setShowSearchbox] = useState(false)
  const [showNoRoomFoundDialog, setShowNoRoomFoundDialog] = useState(false)
  const [contentExpired, setContentExpired] = useState(false)

  useEffect(() => {
    document.title = t("accommodations.Details.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
    saveEnabledStep("hotel")
  }, [t])

  useEffect(() => {
    const loadCart = async () => {
      setIsFetchingContent(true)
      const id = getCartId()
      try {
        const result = await getCart(config, id, searchParams)
        setCart(result)
      } catch (e) {
        console.log(e)
        setIsFetchingContent(false)
      }
    }

    if (!cart) {
      loadCart()
    }
  }, [config, searchParams, cart])

  useEffect(() => {
    const getAccommodationInfo = async (id: string): Promise<any> => {
      try {
        return await getAccommodationInformation(config, id)
      } catch (err) {
        console.error("error", err)
      }
      return null
    }

    const getRates = async (hotelID: string): Promise<any[]> => {
      try {
        const clusters = await getRoomClusters(config, cart!, hotelID, searchParams)
        if (clusters && clusters.length) {
          return clusters
        }
      } catch (err: any) {
        // Check message: {"code":404,"message":"itineraries not found"} => content expired
        const errMsg = JSON.parse(err.message)
        if (errMsg && errMsg.message && errMsg.message === "itineraries not found") {
          setContentExpired(true)
        }
        console.error(err)
      }
      return []
    }

    const compareRates = (r1: RoomClusterRate, r2: RoomClusterRate) => {
      if (r1.meal_plan !== r2.meal_plan) return false
      if (r1.rooms?.length !== r2.rooms?.length) return false
      for (let i = 0; i < r1.rooms!.length; i++) {
        if (r1.rooms![i].id !== r2.rooms![i].id) return false
        if (r1.rooms![i].bed_configuration.length !== r2.rooms![i].bed_configuration.length) return false
        for (let j = 0; j < r1.rooms![i].bed_configuration.length; j++) {
          if (r1.rooms![i].bed_configuration[j].type !== r2.rooms![i].bed_configuration[j].type) return false
          if (r1.rooms![i].bed_configuration[j].size !== r2.rooms![i].bed_configuration[j].size) return false
          if (r1.rooms![i].bed_configuration[j].count !== r2.rooms![i].bed_configuration[j].count) return false
        }
      }
      if (r1.cancel_penalties.length !== r2.cancel_penalties.length) return false
      for (let i = 0; i < r1.cancel_penalties.length; i++) {
        if (r1.cancel_penalties[i].amount !== r2.cancel_penalties[i].amount) return false
        if (r1.cancel_penalties[i].percentage !== r2.cancel_penalties[i].percentage) return false
        if (r1.cancel_penalties[i].start_date !== r2.cancel_penalties[i].start_date) return false
        if (r1.cancel_penalties[i].start_time !== r2.cancel_penalties[i].start_time) return false
        if (r1.cancel_penalties[i].end_date !== r2.cancel_penalties[i].end_date) return false
        if (r1.cancel_penalties[i].end_time !== r2.cancel_penalties[i].end_time) return false
      }
      if (r1.price.total !== r2.price.total) return false
      return true
    }

    const getSelectedRate = async (clusters: any[]) => {
      if (clusters.length === 0) return [null, false]

      let found = true
      let rate = clusters[0].rates[0]

      if (cart!.accommodation.accommodation_id === match.params.hotelId) {
        found = false
        let currentCluster: any = null
        try {
          currentCluster = await getRoomClusterById(config, cart!, cart!.accommodation.rate_id)
        } catch (e) {}

        clusters.forEach((c) => {
          for (let i = 0; i < c.rates.length; i++) {
            c.rates[i].selected = false
            if (!currentCluster && c.rates[i].fare.total === cart!.total) {
              rate = c.rates[i]
              found = true
            }
            if (currentCluster && compareRates(currentCluster.rates[0], c.rates[i])) {
              rate = c.rates[i]
              found = true
            }
          }
        })
      }

      rate.selected = true
      return [rate, found]
    }

    const getDetailResult = async (id) => {
      setIsFetchingContent(true)
      const acc = await getAccommodationInfo(id)
      setAccommodation(acc)
      setIsFetchingContent(false)

      if (searchParams.leg.from && searchParams.leg.to && searchParams.rooms) {
        setCheckin(searchParams.leg.from)
        setCheckout(searchParams.leg.to)
        setRooms(searchParams.rooms)
        setTotalGuests(getGuests(searchParams.rooms))

        setIsFetchingRates(true)
        const clusters = await getRates(id)
        const [sr, srFound] = await getSelectedRate(clusters)
        setRoomClusters(clusters)
        setShowNoRoomFoundDialog(clusters.length === 0)
        setSelectedRate(sr)
        setShowRoomAlert(!srFound)
        setIsFetchingRates(false)
      }
    }

    if (cart) {
      getDetailResult(match.params.hotelId)
    }
  }, [match, cart, config, searchParams])

  const onRateSelect = (clusterIndex: number, rateIndex: number) => {
    setRoomClusters(
      roomClusters.map((e, i) => {
        return {
          ...e,
          rates: e.rates.map((re, ri) => {
            return { ...re, selected: clusterIndex === i && rateIndex === ri }
          }),
        }
      })
    )
    setSelectedRate(roomClusters[clusterIndex].rates[rateIndex])
  }

  const checkRateAndGoToCheckout = async (id: string) => {
    /*
    const res = await checkRate(config, id)
    if (res.status !== "ok" || selectedRate?.refundable !== res.rate.refundable) {
      setRoomNotAvailableForCheckout(true)
      animateScrollTo(document.querySelector("#root") as HTMLElement)
      return
    }
    */

    try {
      await updateCart(config, "ACCOMMODATION", {
        ...cart!,
        accommodation: {
          ...cart!.accommodation,
          rate_id: id,
          accommodation_id: match.params.hotelId,
          name: accommodation!.name,
        },
      })
      let path = searchParamsToCheckoutPath(cart!.id, searchParams)
      path = addGA(location.search, path)
      window.location.href = path
    } catch (e) {
      console.log(e)
    }
  }

  const onViewRooms = () => {
    animateScrollTo(document.querySelector("#hotelRooms") as HTMLElement)
  }

  const onRoomCheckout = (rateID: string) => {
    checkRateAndGoToCheckout(rateID)
  }

  const handleSearch = (params: SearchboxParams) => {
    setCart(null)
    removeCartState()
    let path = searchParamsToNewFlightResultsPath(params)
    history.push(path)
  }

  const handleOpenSearchbox = () => {
    setShowSearchbox(true)
  }

  const handleCloseSearchbox = () => {
    setShowSearchbox(false)
  }

  const handleCloseRoomAlert = () => {
    setShowRoomAlert(false)
  }

  const handleCartItemsExpired = () => {
    removeCartState()
    setContentExpired(false)
    const url = `/combined/flights${location.search}`
    history.push(url)
  }

  const handleGoToResults = () => {
    const url = `/combined/accommodations${location.search}`
    history.push(url)
  }

  const handleChangeRoom = () => {
    setRoomNotAvailableForCheckout(false)
    const url = `/combined/accommodations/${accommodation!.id}${location.search}`
    history.push(url)
  }

  const handleOnCloseNoRoomFoundDialog = () => {
    setShowNoRoomFoundDialog(false)
  }

  const handleStepClick = (type: StepType) => {
    let path = ""
    switch (type) {
      case "flight":
        path = searchParamsToFlightResultsPath(cart!.id, searchParams)
        break
      case "hotel":
        path = searchParamsToHotelResultsPath(cart!.id, searchParams)
        break
      case "checkout":
        path = searchParamsToCheckoutPath(cart!.id, searchParams)
        break
    }
    path = addGA(location.search, path)
    window.location.href = path
  }

  if (isFetchingContent || isFetchingRates) {
    return (
      <Wrapper>
        <div className={styles.background(theme)}>
          <div className={styles.mainContainer}>
            <ProductLoader is_mobile={isMobile} product_variant="ACCOMMODATIONS_ROOM_RESULT" variant="WEB" />
          </div>
        </div>
      </Wrapper>
    )
  }

  if (contentExpired) {
    return (
      <Wrapper>
        <div className={styles.background(theme)}>
          <div className={styles.mainContainer}>
            <HotelNotAvailableError onGoBack={handleCartItemsExpired} />
          </div>
        </div>
      </Wrapper>
    )
  }

  if (!accommodation) {
    return (
      <Wrapper>
        <div className={styles.background(theme)}>
          <div className={styles.mainContainer}>
            <HotelNotAvailableError onGoBack={handleGoToResults} />
          </div>
        </div>
      </Wrapper>
    )
  }

  if (roomNotAvailableForCheckout) {
    return (
      <Wrapper>
        <div className={styles.background(theme)}>
          <div className={styles.mainContainer}>
            <RoomNotAvailableError onGoBack={handleGoToResults} onChangeRoom={handleChangeRoom} />
          </div>
        </div>
      </Wrapper>
    )
  }

  const taxesDisclaimers = []
  taxesDisclaimers.push(t("accommodations.Details.taxes_included"))

  const paisTax =
    selectedRate && selectedRate.fare!.fees ? selectedRate.fare!.fees.find((f: any) => f.type === "PAIS") : undefined
  const rg4815Tax =
    selectedRate && selectedRate.fare!.fees
      ? selectedRate.fare!.fees.find((f: any) => f.type === "RG4815/20")
      : undefined
  const rg5272Tax =
    selectedRate && selectedRate.fare!.fees
      ? selectedRate.fare!.fees.find((f: any) => f.type === "RG5272/22")
      : undefined

  if (paisTax) taxesDisclaimers.push(t("accommodations.Details.pais_tax"))
  if (rg4815Tax) taxesDisclaimers.push(t("accommodations.Details.rg4815_tax"))
  if (rg5272Tax) taxesDisclaimers.push(t("accommodations.Details.rg5272_tax"))

  const drawClustersComponent = () => {
    if (!roomClusters || !selectedRate) return <></>
    return (
      <RoomClusters
        id="hotelRooms"
        isLoading={isFetchingContent}
        roomClusters={roomClusters}
        totalGuests={totalGuests}
        selectedRate={selectedRate}
        taxesDisclaimers={taxesDisclaimers}
        onRateSelect={onRateSelect}
        onRoomCheckout={onRoomCheckout}
      />
    )
  }

  const drawInfoComponent = () => {
    if (isMobile) return <></>
    return (
      <>
        <AccommodationDetailSummary
          loading={false}
          availability={selectedRate != null && roomClusters.length > 0}
          taxesDisclaimers={taxesDisclaimers}
          rate={selectedRate}
          checkin={checkin}
          checkout={checkout}
          totalGuests={totalGuests}
          totalRooms={rooms.length}
          variant="WEB"
          onOpenSearch={handleOpenSearchbox}
          onViewRooms={onViewRooms}
        />
      </>
    )
  }

  const drawMobilePriceBox = () => {
    if (!selectedRate) return <></>
    return <RateTotalWeb isMobile={true} rate={selectedRate} taxesDisclaimers={taxesDisclaimers} />
  }

  const drawStepperComponent = () => {
    if (!cart) return <></>

    return (
      <Stepper
        accommodation={{
          hotelName: cart.accommodation.name,
          totalRooms: cart.accommodation.total_rooms,
        }}
        flight={{
          origin: cart.flight.origin,
          destination: cart.flight.destination,
          from: moment(cart.flight.from),
          to: moment(cart.flight.to),
          totalPassengers: cart.flight.total_passengers,
        }}
        stickyBoundary={{
          top: 0,
          bottom: "#mainContainer",
        }}
        currentStep="hotel"
        enabledStep={getEnabledStep()}
        onStepClick={handleStepClick}
      />
    )
  }

  const drawSearchbox = (wide: boolean) => {
    const searchboxConfig = {
      minDate: moment().add(config.search_configuration.min_days, "days"),
      maxDate: moment().add(config.search_configuration.max_days, "days"),
      minNights: 1,
      maxNights: config.search_configuration.range_days,
      maxRooms: 4,
      maxRoomCapacity: 8,
    }
    return (
      <Searchbox
        getRegions={getRegionsForFlights(i18n.language, config.agency_id, config.country)}
        getAirlines={getAirlineAutocomplete(i18n.language, config.agency_id, config.country)}
        config={searchboxConfig}
        params={searchParams}
        onSearch={handleSearch}
      />
    )
  }

  return (
    <Wrapper>
      <NoRoomFoundErrorDialog
        open={showNoRoomFoundDialog}
        onGoBack={handleGoToResults}
        onClose={handleOnCloseNoRoomFoundDialog}
      />

      {!isMobile && (
        <Dialog fullWidth maxWidth="md" open={showSearchbox} onClose={handleCloseSearchbox}>
          {drawSearchbox(true)}
        </Dialog>
      )}
      <div id="mainContainer" className={styles.mainContainer}>
        <div id="stepperContainer" className={styles.stepper}>
          {drawStepperComponent()}
        </div>
        {!isFetchingRates && showRoomAlert && (
          <div className={styles.alertContainer}>
            <Alert onClose={handleCloseRoomAlert}>
              <Text size={14} variant="regular">
                {t("accommodations.Details.notAvailableCart")}
              </Text>
            </Alert>
          </div>
        )}
        {isMobile && (
          <div className={styles.mobileHeaderContainer}>
            <div className={styles.mobileItemContainer}>{drawSearchbox(false)}</div>
            <div className={styles.mobileItemContainer}>{drawMobilePriceBox()}</div>
          </div>
        )}
        <AccommodationDetail
          accomomdation={accommodation}
          googleMapsApiKey="AIzaSyDGPT1jvVhMQtKD3dtZNFu_2iuxBeGH5ns"
          detailComponent={drawClustersComponent()}
          infoComponent={drawInfoComponent()}
        />
      </div>
    </Wrapper>
  )
}

export default Details
