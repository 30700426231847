import { Cart } from "../utils/types/combined"
import { FetchError } from "@basset-la/components-flights/dist/model"
import { SearchboxParams } from "@basset-la/components-combined/dist/models"
import { mapSearchboxParamsToUrl } from "../utils/helpers/common"
import { getSessionId } from "../utils/helpers/SessionStorage"
import { Config } from "@basset-la/components-commons"

const createQueryString = (mapQs: any) => new URLSearchParams(mapQs).toString()

export const getCart = async (config: Config, id: string, params: SearchboxParams): Promise<Cart> => {
  const filtersStr = "".concat(
    createQueryString({
      site: config.country,
      channel: "WEB",
      source: config.source,
      language: config.language,
    }),
    "&",
    createQueryString(id ? { cart_id: id } : { ...mapSearchboxParamsToUrl(params) })
  )
  const url = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
    ? `${process.env.REACT_APP_COMBINED_PRODUCTS_API_URL}?${filtersStr}`
    : ""

  const results = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY || "",
      "x-client-id": config.agency_id,
      "x-session-id": getSessionId() || "",
    },
  })
  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }

  return json
}

export const updateCart = async (config: Config, product: "FLIGHT" | "ACCOMMODATION", cart: Cart) => {
  const filtersStr = "".concat(
    createQueryString({
      product: product,
      site: config.country,
      channel: "WEB",
      source: config.source,
      language: config.language,
    })
  )
  const body = product === "FLIGHT" ? cart.flight : cart.accommodation
  const url = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
    ? `${process.env.REACT_APP_COMBINED_PRODUCTS_API_URL}/cart/${cart.id}?${filtersStr}`
    : ""

  const results = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY || "",
      "x-client-id": config.agency_id,
    },
    body: JSON.stringify(body),
  })
  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }
  return json
}
